import { takeEvery, takeLatest, call, put, select } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import { api_get, api_post, api_put } from '../utils/api';
import Cookies from 'js-cookie';
import { COOKIE_USER } from '../config';
const n_per_page = 50;

const delay = (ms) => new Promise(res => setTimeout(res, ms))
export const getPage = (state) => state.matchesReducer.page;
export const getSortOrder = (state) => state.matchesReducer.sort_order;
export const getSortField = (state) => state.matchesReducer.sort_field;
export const getFilters = (state) => state.matchesReducer.applied_filters;
export const getLoadFacets = (state) => state.matchesReducer.loadFacets;
export const getUser = (state) => state.access.current_user;
export const getIsLoading = (state) => state.matchesReducer.isLoading;
export const getSearchText = (state) => state.matchesReducer.searchText;
export const getTalent = (state) => state.talentProfileReducers.talent;
export const getIsFirstTimeLoading = (state) => state.matchesReducer.first_load;

/**
 * Load matches
 */
export function* watcherRequestMatchesSaga() {
    yield takeEvery(ActionTypes.DATA_REQUESTED, workerSaga);

}
/**
 * Retrieves the documents according to filters options
 */
function* workerSaga() {
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        if (!u || !u.talent_id) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
            return;
        }
        let talent_id = u.talent_id
        let page = yield select(getPage);
        let sort_order = yield select(getSortOrder);
        let sort_field = yield select(getSortField);
        let filters = yield select(getFilters) || [];
        let load_facets = yield select(getLoadFacets);
        let talent = yield select(getTalent);
        let body = { talent_id: talent_id };
        body.n_per_page = n_per_page;
        body.page_index = page - 1;
        body.sort_order = sort_order;
        body.sort_field = sort_field;
        body.load_facets = load_facets;
        body.filters = filters;
        const response = yield call(
            api_put,
            `/api/v1/matches/talents`,
            body
        );
        if (response.error)
            throw response;

        if (load_facets) {
            if (talent && talent.availabilitytowork && ['More than 30 hrs/week', 'Less than 30 hrs/week'].indexOf(talent.availabilitytowork) > -1) {
                const availability_filters = [{ field_id: "job.dedication", value: talent.availabilitytowork, subtitle: "dedication" },
                { field_id: "job.dedication", value: 'Unknown', subtitle: "dedication" },
                { field_id: "job.dedication", value: 'Unspecified', subtitle: "dedication" }]
                response.availability_filters = availability_filters;
            }

        }
        if (load_facets) {//        if (fistTimeLoading) {
            //const cloneFacets = _.clone(response.facets[0]);
            const cloneFacets = response.initial_facets ? Object.assign({}, response.initial_facets[0]):{};
            yield put({ type: ActionTypes.SET_INITIAL_FACETS, initial_facets:  cloneFacets });

        }
        yield put({ type: ActionTypes.DATA_LOADED, payload: response });


    } catch (e) {
        //console.log("Error getting matches", e);
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.API_ERRORED, error: e });
        }
    }

}

/**
 * Ratings
 */
export function* watcherSetRatingSaga() {
    yield takeEvery(ActionTypes.SET_RATING, workerRatingSaga);
}
/**
 * Set a rating value for a given document
 * @param {*} action 
 */
function* workerRatingSaga(action) {
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        let talent_id = u.talent_id
        const { match_id, rating, comment } = action.payload;
        //const result = yield call(setRating, talent_id, match_id, rating, comment);
        const response = yield call(
            api_post,
            '/api/v1/ratings',
            { talent_id, match_id, rating, comment }
        );
        yield put({ type: ActionTypes.RATING_SET, payload: response.data });
    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.API_ERRORED, error: e });
        }
    }
}
/**
 * Trigger action: Searches for favorites matches selected by a user
 */
export function* watcherLoadFavoriteCountSaga() {
    yield takeEvery(ActionTypes.FAVORITES_COUNT_REQUESTED, workerGetFavoriteCountSaga);
}
/**
 * Searches for favorites matches selected by a user
 * @param {*} action 
 */
function* workerGetFavoriteCountSaga(action) {
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        let talent_id = u.talent_id
        if (!talent_id) {
            return;
        }

        const response = yield call(
            api_get,
            `/api/v1/ratings/favorites/talents/${talent_id}`,
        );
        yield put({ type: ActionTypes.FAVORITES_COUNT_SUCCEEDED, count: response.count });
    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.FAVORITES_COUNT_FAILED });
        }
    }
}
/**
 * Pagination
 */
// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchChangePageSaga() {
    yield takeEvery(ActionTypes.CHANGE_PAGE, reloadData)
}

/**
 * Watches for changes in query filters such "date from" and "date to"
 */
export function* watchQueryFilterSaga() {
    // yield delay(2000);
    yield takeLatest(ActionTypes.DATA_FILTER_REQUESTED, reloadData);
    yield takeLatest(ActionTypes.ADD_FILTER, reloadData)
    yield takeLatest(ActionTypes.REMOVE_FILTER, reloadData)
}
export function* reloadData() {
     yield delay(1000);
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        if (!u || !u.talent_id) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
            return;
        }
        let talent_id = u.talent_id

        let searchText = yield select(getSearchText);
        let page = yield select(getPage);
        let sort_order = yield select(getSortOrder);
        let sort_field = yield select(getSortField);
        let filters = yield select(getFilters);
        let load_facets = yield select(getLoadFacets);

        let body = { talent_id: talent_id, searchText: searchText };
        body.n_per_page = n_per_page;
        body.page_index = page - 1;
        body.sort_order = sort_order;
        body.sort_field = sort_field;
        body.load_facets = load_facets;
        body.filters = filters;
        let response = null;
        // 
        if (searchText) {
            response = yield call(
                api_put,
                `/api/v1/matches/text`,
                body
            );
        }
        else {
            response = yield call(
                api_put,
                `/api/v1/matches/talents`,
                body
            );
        }
        if (response.error)
            throw response;

        yield put({ type: ActionTypes.DATA_LOADED, payload: response });
    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.API_ERRORED, error: e });
        }
    }
}


/**
 * Actions
 */
export function* watcherIncreaseActionCounterSaga() {
    yield takeEvery(ActionTypes.MATCH_ACTION_REQUESTED, workerActionsSaga);
}

function* workerActionsSaga(action) {
    try {
        const { match_id, click_action } = action;
        yield call(
            api_put,
            `/api/v1/matches/${match_id}/actions`,
            { click_action }
        );


        yield put({ type: ActionTypes.MATCH_ACTION_SUCCEEDED });
    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.MATCH_ACTION_SUCCEEDED });

            // yield put({ type: ActionTypes.API_ERRORED, error: e });
        }
    }
}


export function* watcherGetMatchingProgressSaga() {
    yield takeEvery(ActionTypes.MATCHING_PROGRESS_REQUESTED, workerGetMatchingProgressSaga);
}

function* workerGetMatchingProgressSaga(action) {
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        let talent_id = u.talent_id
        if (!talent_id) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
            return;
        }
        const response = yield call(
            api_get,
            `/api/v1/talents/matching-progress/${talent_id}`,
        );
        yield put({ type: ActionTypes.MATCHING_PROGRESS_SUCCEEDED, progress: response.progress });
    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.MATCHING_PROGRESS_FAILED });
        }
    }
}

/**
 * Load matches by text
 */
export function* watcherSearchByTextSaga() {
    yield takeEvery(ActionTypes.SEARCH_TEXT_REQUESTED, workerTextSearchSaga);

}
/**
 * Retrieves matches by text and excludes words if any was provided by the user.
 * @param {*} action 
 */
function* workerTextSearchSaga(action) {
    try {
        let u = Cookies.getJSON(COOKIE_USER);
        if (!u || !u.talent_id) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
            return;
        }
        let talent_id = u.talent_id
        let body = { talent_id: talent_id, searchText: action.includeText, excludeSearch: action.excludeText || '' };

        let load_facets = yield select(getLoadFacets);
        body.load_facets = load_facets;

        const response = yield call(
            api_put,
            `/api/v1/matches/text`,
            body
        );
        if (response.error)
            throw response;
        yield put({ type: ActionTypes.DATA_LOADED, payload: response });
        yield put({ type: ActionTypes.FACETS_RELOADED, facets: response.facets });


    } catch (e) {
        if (e.status === 401) {
            yield put({ type: ActionTypes.FORCE_LOGIN });
        }
        else {
            yield put({ type: ActionTypes.API_ERRORED, error: e });
        }
    }

}
