import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./configureStore";
import App from "./App";
import {
  FACEBOOK_PIXEL_ID,
  GOOGLE_TAG_MANAGER_ID,
  GOOGLE_ANALYTICS_ID,
} from "./config";
import $ from "jquery";
import boostrap from "bootstrap";

import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
};
if (
  !process.env.REACT_APP_NODE_ENV ||
  (process.env.REACT_APP_NODE_ENV !== "development" &&
    process.env.REACT_APP_NODE_ENV !== "test")
) {
  TagManager.initialize(tagManagerArgs);
  ReactPixel.init(FACEBOOK_PIXEL_ID);
  ReactPixel.pageView();
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}
//
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
