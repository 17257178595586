import { all, fork } from "redux-saga/effects";
import {
  watchGetProfile,
  watchLogout,
  watchLoginSaga,
  watchSignupSaga,
  watchPasswordForgotSaga,
  watchPasswordChangeSaga,
  watchPasswordResetSaga,
  watchOauthSaga,
  watchResendActivationnSaga,
  watchAccountActivationSaga,
  watchValidateAccessTokenSaga,
  watchForceLoginTokenSaga,
} from "./access.sagas";
import watchToggleModal from "./modals.sagas";
import {
  watcherRequestMatchesSaga,
  watcherSetRatingSaga,
  watchChangePageSaga,
  watchQueryFilterSaga,
  watcherLoadFavoriteCountSaga,
  watcherIncreaseActionCounterSaga,
  watcherGetMatchingProgressSaga,
  watcherSearchByTextSaga,
} from "./matches.sagas";
import {
  watcherRequestSubscriptionPlansSaga,
  watcherSubscribeWithPaymentMethodSaga,
  watcherStorePaymentMethodSaga,
  watcherGetTalentCurrentSubscriptionSaga,
  watcherGetTalentSubscriptionBannerSaga,
  watcherRemoveCreditCardSaga,
  watcherLoadSubscriptionByIdSaga,
  watcherGetPlanChangeOptionsSaga,
  watcherGetPlanSaga,
  watcherChangePlanSaga,
} from "./subscription.sagas";
import {
  watcherSaveTalentProfileSaga,
  watcherLoadTalentProfileSaga,
  watcherSaveProfessionalProfileSaga,
  watcherSavePersonalInfoSaga,
  watcherSaveWorkPreferencesSaga,
  watcherSaveCommunicationPreferenceSaga,
  watcherUpdateCustomerSaga,
  watcherLoadCustomerByTalentIdSaga,
  watcherAddCustomerTaxIdSaga,
  watcherDeleteCustomerTaxIdSaga,
  watcherCancelSubscriptionSaga,
  watcherGetCustomerPaymentMethodSaga,
  watcherDeleteAccountSaga,
  watcherApplyCouponToSubscriptionSaga,
  watcherUpdateTalentSaga,
  watcherRunMatchingForTalentSaga,
  watcherLoadCategoryJobPreviewSaga,
  watcherFilterCategoryJobPreviewSaga,
  watcherCreateTalentMatchesForCategorieswSaga,
  watcherRequestAllSkillsSaga,
} from "./talent-profile.sagas";
import { watcherSaveSkill } from "./admin.sagas";
/**
 * Watches all action request to the server
 */
export default function* rootSaga() {
  yield all([
    fork(watchGetProfile),
    fork(watchLoginSaga),
    fork(watchSignupSaga),
    fork(watchValidateAccessTokenSaga),
    fork(watchForceLoginTokenSaga),
    fork(watchLogout),
    fork(watchToggleModal),
    fork(watcherRequestMatchesSaga),
    fork(watcherSetRatingSaga),
    fork(watchChangePageSaga),
    fork(watchQueryFilterSaga),
    fork(watcherRequestSubscriptionPlansSaga),
    fork(watcherSaveTalentProfileSaga),
    fork(watcherLoadTalentProfileSaga),
    fork(watchPasswordForgotSaga),
    fork(watchPasswordChangeSaga),
    fork(watchPasswordResetSaga),
    fork(watcherSaveProfessionalProfileSaga),
    fork(watcherSavePersonalInfoSaga),
    fork(watcherSaveWorkPreferencesSaga),
    fork(watcherSaveCommunicationPreferenceSaga),
    fork(watchOauthSaga),
    fork(watchResendActivationnSaga),
    fork(watchAccountActivationSaga),
    fork(watcherSubscribeWithPaymentMethodSaga),
    fork(watcherStorePaymentMethodSaga),
    fork(watcherGetCustomerPaymentMethodSaga),
    fork(watcherGetTalentCurrentSubscriptionSaga),
    fork(watcherCancelSubscriptionSaga),
    fork(watcherLoadFavoriteCountSaga),
    fork(watcherUpdateCustomerSaga),
    fork(watcherLoadCustomerByTalentIdSaga),
    fork(watcherAddCustomerTaxIdSaga),
    fork(watcherDeleteCustomerTaxIdSaga),
    fork(watcherGetTalentSubscriptionBannerSaga),
    fork(watcherDeleteAccountSaga),
    fork(watcherApplyCouponToSubscriptionSaga),
    fork(watcherIncreaseActionCounterSaga),
    fork(watcherUpdateTalentSaga),
    fork(watcherRunMatchingForTalentSaga),
    fork(watcherGetMatchingProgressSaga),
    fork(watcherLoadCategoryJobPreviewSaga),
    fork(watcherFilterCategoryJobPreviewSaga),
    fork(watcherSearchByTextSaga),
    fork(watcherRemoveCreditCardSaga),
    fork(watcherLoadSubscriptionByIdSaga),
    fork(watcherGetPlanChangeOptionsSaga),
    fork(watcherGetPlanSaga),
    fork(watcherChangePlanSaga),
    fork(watcherCreateTalentMatchesForCategorieswSaga),
    fork(watcherRequestAllSkillsSaga),
    fork(watcherSaveSkill),
  ]);
}
