
import { RESET_TOAST_MESSAGE, SET_INFO_MESSAGE, SET_ERROR_MESSAGE } from '../constants/actionTypes';

export const resetMessage = () => {
  return { type: RESET_TOAST_MESSAGE };
}
export const displayInfoMessage = (message) => {
  return { type: SET_INFO_MESSAGE, message: message };
}
export const displayErrorMessage = (message) => {
  return { type: SET_ERROR_MESSAGE, message: message };
}
