export const REQUIRED_GENERAL_ERROR = "Required.";
export const INVALID_EMAIL_ADDRESS_GENERAL_ERROR = "Invalid email address.";
export const SUCCEEDED_GENERAL_MESSAGE = "Changes Saved";
export const INVALID_FORM_GENERAL_ERROR =
  "You have errors in this form, please check.";
export const CANCEL_BUTTON = "Cancel";
export const LOADING_GENERAL =
  "Please wait. Don't close or refresh the page ...";
export const YES_BUTTON = "Yes";
export const NO_BUTTON = "No";

//Login
export const FORGOT_PASS_QUESTION = "Forgot your password?";

/**
 * Professional profile
 */
export const MAXIMUN_10000_GENERAL_ERROR =
  "Maximun of 25.000 characters exceeded.";
export const MINIMUN_500_GENERAL_ERROR =
  "Please enter at least 500 characters.";
export const MINIMUN_250_GENERAL_ERROR =
  "Please enter at least 250 characters.";
export const MINIMUN_5_OPTIONS_ERROR = "Please add at least 5 entries";
export const SHORT_TEXT_GENERAL_WARNING =
  "You have entered an insufficient amount of information. Our artificial intelligence algorythms need more information to find great matches for you. Please enter more detailed and complete data. It is all for your own benefit, thank you for helping our matching system.";
export const SHORT_TEXT_IDEAL_JOB_WARNING =
  "Tell us more about your ideal job.";
export const SHORT_TEXT_OTHER_PROJECTS_WARNING =
  "Please enter more detailed and complete data about your projects.";
export const NO_SUBCATEGORIES_ERROR =
  "You should add at least one subcategory.";
export const NO_NATIVE_LANGUAGES_ERROR =
  "You should add at least one native language.";
export const NO_FLUENT_LANGUAGES_ERROR =
  "You should add at least one fluent language.";
export const URL_EMPTY_ERROR = "Please enter a URL or remove it from the list.";
export const INVALID_URL = "Invalid URL";
export const CV_LOAD_SUCESSFULLY = "CV loaded";
export const CV_LOAD_ERROR =
  "There was an error uploading your CV, pleasy try again";

/**
 * Account activation
 */
export const ACTIVATE_ACCOUNT_CLICK_CONFIRM =
  "Please click the button below to confirm you wish to activate your account.";
/**
 * Subscription
 */
export const CHOOSE_PLAN_TITLE_SUBSCRIPTION = "Choose your subscription plan";
export const CHOOSE_PLAN_TITLE_SUBSCRIPTION_TRIAL =
  "Activate your 14-days free trial. You will be able to choose a free or paid plan after that";
export const CHOOSE_PLAN_TITLE_TRIAL_ENDED =
  "Your free trial has ended. Please confirm if you want to downgrade to free or stay on a paid plan";
export const FOREVER_BUTTON_DISABLED_AFTER_TRIAL_MESSAGE =
  "AVAILABLE AFTER PRO TRIAL";
export const FOREVER_BUTTON_MESSAGE = "select";

export const CHOOSE_PLAN_TITLE_NOCARD_SUBSCRIPTION =
  "Choose your subscription plan";
export const CARD_INSTRUCTIONS_SUBSCRIPTION_MESSAGE =
  "Please enter your credit card information.";
export const CARD_NOCHARGED_MONTHLY_PLAN_SUBSCRIPTION_MESSAGE =
  "Your card won't be charged until the free trial is over. No worries, we'll notify you before it happens.";
export const SUBSCRIPTION_SELECT_PLAN_ERROR =
  "Please choose a subscription plan.";
export const NOCARD_SUBSCRIPTION_SELECT_PLAN_ERROR =
  "Please choose a subscription plan, no credit card required!";
export const NOCARD_SUBSCRIPTION_MESSAGE = `No credit card required now. We'll notify you 3 days before the trial is over so you can provide your payment details to continue enjoying our service`;
export const TRIAL_TEXT_BUTTON = "Start free trial";
export const SUBSCRIPTION_SELECT_PAYMENT_ERROR = "Please add a payment method.";
export const CANCEL_SUBSCRIPTION_BUTTON = "Cancel subscription";
export const CANCEL_SUBSCRIPTION_QUESTION =
  "Are you sure you want to cancel your subscription?";
export const CANCEL_SUBSCRIPTION__INFO = `By canceling your subscription we will stop the service at the end of the current billing or trial period.`;
export const CANCEL_SUBSCRIPTION_CLOSE_BUTTON = "Close";
export const CANCEL_SUBSCRIPTION_CONFIRM_BUTTON = "Confirm cancellation";
export const CHANGE_CARD_BUTTON = "Change card";
export const ADD_CARD_BUTTON = "Add card";

export const SUBSCRIPTION_SECTION_TITLE = "Your subscription details";
export const PAYMENT_SECTION_TITLE = "Your payment methods";
export const BILLING_SECTION_TITLE = "Your billing details";
export const COPY_PERSONAL_ADDRESS = "copy from my personal info";
export const SUBSCRIPTION_UNPAID_MESSAGE =
  "Please update your credit card information.";
export const SUBSCRIPTION_INCOMPLETE_MESSAGE =
  "Your bank reported an error with your payment. Please provide a different credit card. Your access to matches will be suspended until your payment is received";
export const SUBSCRIPTION_AUTH_MESSAGE =
  "Your bank has requested to verify your identity. Please authorize your payment or provide a different credit card.";
export const SUBSCRIPTION_PAYMENT_IN_PROGRESS =
  "We are processing your payment, please don't close or refresh this window until you receive a confirmation";
export const STORING_PAYMENT_IN_PROGRESS = "Validating your credit card...";
export const SUBSCRIPTION_CHANGE_IN_PROGRESS =
  "We are processing your request, please don't close or refresh this window until you receive a confirmation";
export const ACTION_PAYMENT_METHOD_REQUIRED = "Payment method required";
export const ACTION_ACTION_REQUIRED = "Action required";
export const ADD_COUPON_ERROR = "Please add a coupon code";
export const INSERT_COUPON_CODE_PLACEHOLDER = "Coupon code";
export const BILLING_DETAILS_EMPTY_ERROR =
  "Please provide your billing details first.";
export const COUPON_CODE_TITLE = "Got a coupon? Redeem it here.";
export const NO_SUBSCRIPTION = "You don't have any active subscriptions.";
export const SUBSCRIPTION_NO_PAYMENT_NEEDED_FOR_TRIAL =
  "No payment methods needed for trial.";
export const SUBSCRIPTION_RESULT =
  "Your payment is confirmed. You'll be redirected to your matches page now";
//Downgrade popup
export const DOWNGRADE_TITLE = "Downgrading to forever free";
export const DOWNGRADE_TITLE_FIRST_TIME = "You will miss the PRO benefits";

export const DOWNGRADE_TEXT =
  "This is how your service will change after confirmation:";
export const DOWNGRADE_TEXT_FIRST_TIME =
  "This is how your service will look like after confirmation:";
export const DOWNGRADE_CHANGES_LIST = [
  "No unlimited matches (you will receive a maximum of 10 matches per day)",
  "Your daily newsletter will have 3 matches (not 5)",
  "Jobs can be accessed from our platform (not directly from your email)",
  "Matching optimization support won't be available",
];
export const DOWNGRADE_CONFIRMATION = "Are you sure you want to downgrade?";
export const DOWNGRADE_CONFIRMATION_FIRST_TIME =
  "Are you sure you want to miss the pro trial?";

export const RESULT_SUBSCRIPTION_DOWNGRADE =
  "Your subscription has been downgraded to Forever Free";
export const RESULT_SUBSCRIPTION_UPGRADE =
  "Your subscription has been upgraded to Pro";

/* Tax id */
export const REVERSE_VAT_TITLE = "Reverse VAT ? Validate it here";
export const SELECT_COUNTRY_TAX_ID_PLACEHOLDER = "Country of residence";
export const INSERT_VALID_TAX_ID_PLACEHOLDER = "Valid tax id";
export const ADD_PERSONAL_NUMBER_TO_VALIDE_TAX_ID =
  "Please add your Tax ID number";
/** Security */
export const DELETE_ACCOUNT_OPEN_BUTTON_TEXT = "Delete account";
export const DELETE_ACCOUNT_CLOSE_BUTTON_TEXT = "Close";
export const DELETE_ACCOUNT_QUESTION =
  "Are you sure you want to delete your account?";
export const DELETE_ACCOUNT_INFO = `By deleting your account, you will lose all the data and access to matches, account and subscriptions you paid for.\nYou won't be able to access the system and will be logged out once the process of deletion completes.`;
export const MATCHING_COMPLETION_TEXT =
  "You’ll start receiving matches as soon as you reach 80%.";
/** Hint texts on Profile page */
export const PROGRESS_BAR_HINT =
  "The amount of data you provide has a direct impact on the quality of the matches you will receive. Help yourself by filling in as much as you can.";
export const PROFESSIONAL_SUMMARY_HINT =
  "Equivalent to a cover letter, write about your studies, your main accomplishments, your current situation and what you're looking for now. A length of 500 characters is a good starting point.";
export const IDEAL_JOB_HINT =
  "Now is the time to dream. If you would have to write a job description for that dream job, how would it look like? Think about hard skills, its most technical / tactical / operative angles.";
export const SKILLS_HINT =
  "Now is the time to describe at the level of tasks, tools, very specific and to the point. Kind of using a magnifier glass to read that ideal job with the descriptions of your unique set of skills.";
export const OTHER_PROJECTS_HINT =
  "Did you learn a skill in the past that could be useful now? Maybe it's not in the list of descriptors of your ideal job but is something you could also do and be paid for.";
export const SUBCATEGORIES_HINT =
  "Choose as many as possible from the options our system offers depending on the areas of expertise you may have selected.";
export const CV_HINT = "Word format (.docx)";
export const CV_INTRO =
  "This is the last step! You can upload a file or copy/paste your information directly on the textbox. A minimum of 250 characters is suggested";

//Wizard
export const UPLOAD_CV_TITLE = "Upload your CV";
export const UPLOAD_CV_SUBTITLE = "Upload CV";
export const UPLOAD_CV_SUBTITLE_PARAGRAPH = "We accept word format (.docx)";

export const ONLINE_PRESENCE_TITLE =
  "Do you have a website or online portfolio?";
export const ONLINE_PRESENCE_INTRO =
  "Do you have a website, online portfolio, or social site that showcases your work? Add them below! This could be your own personal website, an online portfolio, or a social site such as LinkedIn, Instagram, Twitter. etc.";
export const ONLINE_PORTFOTLIO = "PErsonal website";
export const ONLINE_SOCIAL_PROFILES = "Social profiles";
export const ONLINE_SOCIAL_LINKEDIN = "LinkedIn";
export const ONLINE_SOCIAL_IG = "Instagram";
export const ONLINE_SOCIAL_TWITTER = "Twitter";
export const ONLINE_SOCIAL_GITHUB = "Github";
export const URL_PLACEHOLDER = "Please type your profile URL.";
export const HANDLE_PLACEHOLDER =
  "Please type your profile URL or handle (@example).";

export const EXPERTISE_AREA_TITLE = "Choose area(s) of expertise";
export const EXPERTISE_AREA_INTRO =
  "Tell us what fields you work in by choosing your main areas of expertise first. Then help us narrow our search by adding subcategories.";
export const EXPERTISE_AREA_MAIN = "Area(s) of expertise";
export const EXPERTISE_AREA_SUBCATEGORIES_INTRO =
  "Please add at least one subcategory. But remember, the more information you give us the better we can match you with jobs!";
export const EXPERTISE_AREA_SUBCATEGORIES_PLACEHOLDER =
  "Pick all subcategories that apply to you.";
export const EXPERTISE_AREA_MAIN_PLACEHOLDER =
  "First, choose your expertise areas";
export const EXPERTISE_AREA_MAIN_REQUIRED_ERROR =
  "Please select at least one area of expertise first.";
export const PROFESSIONAL_SUMMARY_TITLE = "Professional summary";
export const PROFESSIONAL_SUMMARY_INTRO =
  "This section is equivalent to a cover letter. Write about your studies, your main accomplishments, your current situation and what you're looking for now. Please enter at least ";
export const PROFESSIONAL_SUMMARY_INTRO2 = "250 characters";
export const PROFESSIONAL_SUMMARY_INTRO3 =
  " to continue. Remember the more information we have the better!";

export const PROFESSIONAL_SUMMARY_HELP =
  "Need help? Click below to view examples.";
export const PROFESSIONAL_SUMMARY_EXAMPLE_BUTTON = "Example";
export const PROFESSIONAL_SUMARY_EXAMPLE = `    Example of a Social Media Marketeer: 
I’m a Business Administrator, recently graduated as a Social Media Marketeer, with 1 year of hands-on experience in existing market products. I’m experienced working intensively with Facebook, Instagram, LinkedIn and Twitter business accounts in the past. 

During the last year I’ve supported social media projects, participating in the strategy definition and working in the creation and distribution of content through Social Media platforms, achieving the reach and engagement goals required for optimal lead conversion measured through Analytics. 

I’ve also supported entrepreneurs on their lead generation strategy definition, working on the selection of the best CRM + email marketing tools (Kajabi, Mailchimp and Active Campaign), creating the email templates and automating distribution for optimal conversion. 

I’m very used to make basic designs in Canva and I’m advanced on email marketing through Mailchimp and blogging in Wordpress`;
export const SKILLS_TITLE = "Add your skills";
export const SKILLS_INTRO =
  "Now is the time to select your unique set of skills, at the level of the tasks you can complete and the tools that you master. If you had a magnifier glass to read the description of your ideal job, the skills that you select here would be the oned used by the client who is looking for your support.";

export const LANGUAGES_TITLE = "What languages do you speak?";
export const LANGUAGES_INTRO = "";

export const IDEAL_JOB_TITLE = "What is your ideal job?";
export const IDEAL_JOB_INTRO =
  "Now is the time to dream. If you had to write a job description for that dream job, what would it look like? Think about the ";
export const IDEAL_JOB_INTRO1 = "hard";
export const IDEAL_JOB_INTRO2 =
  " (technical) skills your ideal job would require and make sure to include them in your description below.";

export const IDEAL_JOB_EXAMPLE = `    Example for a full-stack developer: 
I’m looking for fullstack development jobs focused on backend (node.js / Firebase authentication / API / RESTful API integrations / MySQL / MongoDB / Docker) and frontend (React / Responsive, from the initial transformation from the designer’s wireframes and mockups / Bootstrap / CSS / HTML), all documented in Github with versioning & deployment in Git. I’m a fast learner, happy to explore other complementary technologies. 

I’m used to work on agile environments following scrum methodology, so I’m OK to work with Trello, Jira or other project management tools`;

export const SUCCEEDED_MATCHES_CREATION =
  "The projects that appear to have a good match with your profile have been added to your 'My Matches' section. In order to refine the search, please let us know more about you in the next steps";

//Matches popup
export const MATCHES_POPUP_TITLE = "Matches preview";
export const MATCHES_POPUP_TEXT =
  " projects that appear to be a basic match with your profile have been added to your 'My Matches' section. To get accurate matches, please let us know more about you in the next 3 steps.";
export const MATCHES_POPUP_BUTTON_MATCHES = "SEE BASIC MATCHES";
export const MATCHES_POPUP_BUTTON_CONTINUE = "Continue";

//Completion popup
export const COMPLETION_TITLE = "Your personalized jobs are on their way!";
export const COMPLETION_TEXT =
  "Our intelligent software is analyzing your data and finding matches that fit your job profile! This usually only takes a few moments, but can take up to 10 minutes in some cases.";
export const COMPLETION_TEXT1 =
  "If you need to refine your matches, head to 'My Profile' section and adjust your information";

export const BASIC_OBD_WELCOME = "Welcome to Wisar";
export const BASIC_OBD_WELCOME_TEXT1 =
  "You are just a few steps away from receiving job opportunities perfectly matching your profile.";
export const BASIC_OBD_WELCOME_TEXT2 =
  "We scout the internet for jobs and match them with your profile using our artificial intelligence algorithm.";

export const BASIC_OBD_HOWTO = "How to get best matches?";
export const BASIC_OBD_HOWTO_TEXT1 =
  "The more we know about you, the better the matches our AI will generate for you. Fill out as many fields as possible to get freelance projects that suit you best.";
export const BASIC_OBD_HOWTO_TEXT2 =
  "Your information is protected by our data privacy policy. You can edit it anytime under the ‘My Profile’ section.";

export const BASIC_OBD_NEEDHELP = "Need help?";
export const BASIC_OBD_NEEDHELP_TEXT1 = `If you need help with any part of the process, please chat with us using the button at the bottom right corner of the screen.`;
export const BASIC_OBD_NEEDHELP_BUTTON = "Get Started";

//Settings
export const PERSONAL_INFO_TITLE = "Personal information";
export const WORK_PREFERENCES_TITLE = "Work preferences";
export const COMMUNICATION_PREFERENCES_TITLE = "Communication preferences";
export const SECURITY_TITLE = "Security";
//
export const SEND_FORGOOT_PASSWORD_BUTTON = "Send";
//Job preview
export const JOB_PREVIEW_CLOSED_TITLE1 =
  " jobs found. Click here for a preview! ";

export const JOB_PREVIEW_OPEN_TITLE1 = "We found ";
export const JOB_PREVIEW_OPEN_TITLE2 =
  " jobs that match your profile. Add more information to narrow the results";
export const INTRO_SWITCH_SUBS_PLAN =
  "This is how your service will change after confirmation:";
//matches page
export const EXCLUDE_WORDS_HINT =
  "You must enter a search phrase before specifying words to be excluded";
export const PRESELECTED1 = "We have preselected ";
export const PRESELECTED2 =
  " projects for you but none of them matches your current filters. You can modify your selection to see the rest of the jobs.";

export const NORESULTS1 =
  "As soon as your profile reaches 80% completion, our algorithm starts analysing it to match you with 1000’s of projects from multiple freelance platforms.";
export const NORESULTS2 = "For optimal performance, please review your ";
export const NORESULTS3 = " to get as close as possible to 100% completion.";
export const NORESULTS4 =
  "We update jobs and matches every 2-3 hours. You'll be able to see the effect of your profile adjustments in next batch run.";
export const FREEMIUM_MATCHES_UPGRADE_MESSAGE =
  "You have reached the limit of the free plan. ";
export const FREEMIUM_MATCHES_UPGRADE_MESSAGE2 =
  " your plan to view more daily matches.";

/**
 * Matches modal
 */
export const JUM_TO_PLATFORM = "Jump to original platform";

// Admin
export const ADMIN_TITLE = "Administration";
export const ADMIN_SKILLS_TITLE = "Add a new skill";
