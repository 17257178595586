export const API_URL =
  !process.env.REACT_APP_NODE_ENV ||
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:4002"
    : process.env.REACT_APP_NODE_ENV === "test"
    ? `https://testbackend.wisar.pro`
    : `https://api.wisar.pro`;
export const COOKIE_USER = "wisar_user";
export const DONOTSHOW_PLATFORM_KEY = "do_not_show_platform";
export const WISAR_TOKEN_KEY = "wsr_tr_token";
export const GOOGLE_CLIENT_ID =
  "843056806063-jlcjaiobidbg1jgj3l0lt91k938hml04.apps.googleusercontent.com";
export const LINKEDIN_CLIENT_ID = "77dh8qs376vdg8";
export const LINKEDIN_REDIRECT_URI =
  !process.env.REACT_APP_NODE_ENV ||
  process.env.REACT_APP_NODE_ENV === "development" ||
  process.env.REACT_APP_NODE_ENV === "test"
    ? "https://testbackend.wisar.pro/linkedin"
    : "https://users.wisar.pro/linkedin";
export const STRIPE_PUBLISHABLE_KEY =
  !process.env.REACT_APP_NODE_ENV ||
  process.env.REACT_APP_NODE_ENV === "development" ||
  process.env.REACT_APP_NODE_ENV === "test"
    ? `pk_test_44rFi85heosPcYeKQfBgn2do0028YBxkrG`
    : `pk_live_BYKSGAUmPfNHE6aoM7cMWfJl000Nh6xNkS`;
export const WISAR_SUBSCRIPTION_KEY = "wsr_sc";
export const LAST_LOCATION_KEY = "wsr_loc";
export const FACEBOOK_PIXEL_ID = "478290729519233";
export const GOOGLE_ADS_ID = "AW-667130773";
export const GOOGLE_TAG_MANAGER_ID = "GTM-NZBC2S6";
export const GOOGLE_ANALYTICS_ID = "UA-163274296-1";
export const NOCARD_KEY = "wsr_nc";
export const ELIGIBLE_SUBSCRIPTION_URL_KEY = "ws_sub_ur";
export const WISAR_SUBSCRIPTION_STATUS_KEY = "sts";
export const DEFAULT_STRIPE_PLAN_ID =
  !process.env.REACT_APP_NODE_ENV ||
  process.env.REACT_APP_NODE_ENV === "development" ||
  process.env.REACT_APP_NODE_ENV === "test"
    ? `plan_H6LvWBuWQ6h0zw`
    : "plan_GyZIwZpROvmPWc";

export const FREEMIUM_ROLE = "freemium";
export const PRO_ROLE = "pro";
export const ADMIN_ROLE = "admin";
export const ELEGIBLE_URL_SUBSCRIPTION = "/subscription";
export const ELEGIBLE_URL_TRIAL = "/trial";
