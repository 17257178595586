import React from "react";
import Cookies from "js-cookie";
import {
  AsyncLoginView,
  AsyncSignupView,
  AsyncForgotPaswordView,
  AsyncChangePasswordView,
  AsyncResetPasswordView,
  AsyncActivateAccountView,
  AsyncTalentAccountView,
  AsyncMatchesView,
  AsyncSubscriptionView,
  AsyncNoCardSubscriptionView,
  AsyncOnboardingView,
  AsyncMySubscriptionView,
  AsyncSettingsView,
  AsyncAdminView,
} from "./asyncViews";
import { NavLink } from "react-router-dom";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import {
  WISAR_TOKEN_KEY,
  WISAR_SUBSCRIPTION_KEY,
  LAST_LOCATION_KEY,
  COOKIE_USER,
  WISAR_SUBSCRIPTION_STATUS_KEY,
} from "./config";

const Page404 = () => (
  <div id="mainContent" className="position-relative w-100 d-block">
    <h1 className="h1">Page not found</h1>
    <div className="row">
      <div className="col-12 col-sm-12 signUpForm">
        <div className="signUpCardColumnInner">
          <h2 className="h2">404</h2>
          <p>The page you are requesting does not exist.</p>
          <div>
            <NavLink to="/profile">Profile</NavLink>
            <NavLink to="/account">Account</NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default function Routes() {
  return (
    <Switch>
      <Route exact path="/linkedin" component={LinkedInPopUp} />
      <Route
        exact
        path="/change-password"
        component={AsyncChangePasswordView}
      />
      <Route exact path="/forgot-password" component={AsyncForgotPaswordView} />
      <Route exact path="/reset/:token" component={AsyncResetPasswordView} />
      <Route
        exact
        path="/activate/:token"
        component={AsyncActivateAccountView}
      />
      <Route exact path="/account" component={AsyncMySubscriptionView} />
      <RedirectAuthenticatedToLastRoute strict exact path="/login">
        {" "}
        <AsyncLoginView />{" "}
      </RedirectAuthenticatedToLastRoute>
      <RedirectAuthenticatedToLastRoute strict exact path="/signup">
        {" "}
        <AsyncSignupView />{" "}
      </RedirectAuthenticatedToLastRoute>

      <SubscribedRoute strict exact path="/matches">
        {" "}
        <AsyncMatchesView />{" "}
      </SubscribedRoute>
      <PrivateRoute strict exact path="/subscription">
        {" "}
        <AsyncSubscriptionView />{" "}
      </PrivateRoute>
      <PrivateRoute strict exact path="/trial">
        {" "}
        <AsyncNoCardSubscriptionView />
      </PrivateRoute>
      <SubscribedRoute strict exact path="/profile">
        {" "}
        <AsyncTalentAccountView />{" "}
      </SubscribedRoute>
      <SubscribedRoute strict exact path="/onboard">
        {" "}
        <AsyncOnboardingView />{" "}
      </SubscribedRoute>
      <PrivateRoute strict exact path="/settings">
        {" "}
        <AsyncSettingsView />{" "}
      </PrivateRoute>
      <PrivateRoute strict exact path="/admin">
        {" "}
        <AsyncAdminView />{" "}
      </PrivateRoute>

      <Route
        exact
        path="/"
        render={({ location }) =>
          localStorage.getItem(WISAR_TOKEN_KEY) ? (
            <Redirect
              to={{
                pathname: "/matches",
                state: { from: location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/signup",
                state: { from: location },
              }}
            />
          )
        }
      />

      <Route
        path="/linkedin/success/"
        render={() => (
          <Redirect
            to={{
              pathname: Cookies.get("lastLocation_before_logging"),
              state: { loadUser: true },
            }}
          />
        )}
      />
      <Route component={Page404} />
    </Switch>
  );
}
const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};
/**
 * save cookie with last intended navigation route
 */
const saveLocation = (location) => {
  let fullpath = (location.pathname || "") + location.hash || "";
  if (fullpath) {
    localStorage.setItem(LAST_LOCATION_KEY, fullpath);
  } else {
    localStorage.removeItem(LAST_LOCATION_KEY);
  }
};
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
  let location = useLocation();
  saveLocation(location);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem(WISAR_TOKEN_KEY) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
// Wrapper for "subscription required" route
function SubscribedRoute({ children, ...rest }) {
  let isSubscribed = false;
  let isAuth = localStorage.getItem(WISAR_TOKEN_KEY) ? true : false;
  let sub = localStorage.getItem(WISAR_SUBSCRIPTION_KEY);
  let status = localStorage.getItem(WISAR_SUBSCRIPTION_STATUS_KEY);

  let isUser = typeof Cookies.get(COOKIE_USER) !== "undefined";
  if (
    sub &&
    sub !== null &&
    sub !== "null" &&
    sub !== undefined &&
    sub !== "undefined" &&
    ((status && status === "active") || status === "trialing")
  ) {
    isSubscribed = true;
  }

  let location = useLocation();
  saveLocation(location);

  let url_subsc = "/subscription";

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth && isSubscribed && isUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: url_subsc,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
// Wrapper for "coming from home" route
function RedirectAuthenticatedToLastRoute({ children, ...rest }) {
  const user = Cookies.get(COOKIE_USER);

  let lastPath = "/matches";
  let isAuth = localStorage.getItem(WISAR_TOKEN_KEY) ? true : false;
  let lastVisited = localStorage.getItem(LAST_LOCATION_KEY);
  if (
    lastVisited &&
    lastVisited !== null &&
    lastVisited !== "null" &&
    lastVisited !== undefined &&
    lastVisited !== "undefined"
  ) {
    lastPath = lastVisited;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuth || !user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: lastPath,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
