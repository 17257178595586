import { SET_RATING, DATA_REQUESTED, CHANGE_PAGE, SET_QUERY_FILTER, 
  ADD_FILTER, REMOVE_FILTER, APPLY_FILTERS, SET_ORDER, CLEAR_FILTERS, REMOVE_FILTER_LIST, 
  FAVORITES_COUNT_REQUESTED, TOGGLE_FILTER, MATCH_ACTION_REQUESTED, MATCHING_PROGRESS_REQUESTED, SEARCH_TEXT_REQUESTED,TOGGLE_SECTION, SET_LOAD_INITIAL_FACETS, SET_RECALCULATE_INITIAL_FACETS_COUNT } from "../constants/actionTypes";


/**
 * Query the database with given params.
 */
export function getData() {
  return { type: DATA_REQUESTED };
}
/**
 * This action is triggered when a user clicks on a paginators's page number
 * @param {*} payload : page
 */
export function changePage(payload) {
  return { type: CHANGE_PAGE, payload };
}
/**
 * This action represents like or dislike from user
 * @param {*} payload : match_id, rating, comment
 */
export function setRating(payload) {
  return { type: SET_RATING, payload };
}
/**
 * Optional filters such as dates, favorites are sent to backend because they might need pagination
 * @param {*} payload dateFrom, dateTo, rating
 */
export function setQueryFilter(payload) {
  return { type: SET_QUERY_FILTER, payload };
}
export function addFilter(payload) {
  return { type: ADD_FILTER, payload };
}
export function removeFilter(payload) {
  return { type: REMOVE_FILTER, payload };
}
export function removeFilterList(payload) {
  return { type: REMOVE_FILTER_LIST, payload };
}
/**
 * filters such as checkbox are filtered in the fronted
 * @param {*} payload : event: event,
            field_id: field_id,
            value: value,
            field_type: field_type
 */
export function applyFilters() {
  return { type: APPLY_FILTERS };
}

export function clearFilters() {
  return { type: CLEAR_FILTERS };
}
/**
 * This action is triggered when a user clicks on a checkbox
 * @param {*} field_id 
 * @param {*} value 
 * @param {*} subtitle - parect option name or category
 */
export const toggleFilter = (field_id, value, subtitle) => ({ type:TOGGLE_FILTER, field_id, value, subtitle })
/**
 * This action is triggered when a user clicks on show more/show less to expand section
 * @param {*} section_name 
 */
export const toggleSection = (section_name) => ({ type:TOGGLE_SECTION, section_name})

/**
 * Order creiteria to of displayed fields
 * @param {*} payload:  sort_field: 'Date', sort_label: 'Match date', sort_order: 'asc',
 */
export function setOrder(payload) {
  return { type: SET_ORDER, payload };
}
export const loadFavoritesCount = () => ({ type:FAVORITES_COUNT_REQUESTED })
export const addActionCount=(match_id, click_action)=>({type:MATCH_ACTION_REQUESTED, match_id, click_action})
export const getMatchingProgress = () => ({ type:MATCHING_PROGRESS_REQUESTED })

export const searchByText = (includeText, excludeText) => ({ type:SEARCH_TEXT_REQUESTED, includeText, excludeText });
export const reloadInitialFacets = (bool) => ({ type:SET_LOAD_INITIAL_FACETS, bool });
export const recalculateFacetsCount = (facets) => ({ type:SET_RECALCULATE_INITIAL_FACETS_COUNT, facets });

