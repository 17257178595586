// Actions dispatched in the application

/**
 * General
 */
export const SET_LOADING = "SET_LOADING";
export const SET_INFO_MESSAGE = "SET_INFO_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export const RESET_TOAST_MESSAGE = "RESET_TOAST_MESSAGE";
export const SET_RED_BAR_DISPLAYED = "SET_RED_BAR_DISPLAYED";

export const API_ERRORED = "API_ERRORED";
export const CLEAR_INFO_MESSAGES = "CLEAR_INFO_MESSAGES";
/**
 * Secutity, access, authentication
 */
export const SET_JWT_OBJECT = "SET_JWT_OBJECT";
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const SIGNUP_REQUESTED = "SIGNUP_REQUESTED";
export const SIGNUP_SUCCEEDED = "SIGNUP_SUCCEEDED";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCEEDED = "LOGOUT_SUCCEEDED";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const PROFILE_REQUESTED = "PROFILE_REQUESTED";
export const PROFILE_SUCCEEDED = "PROFILE_SUCCEEDED";
export const PROFILE_FAILED = "PROFILE_FAILED";

export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_SUCCEEDED = "PASSWORD_RESET_SUCCEEDED";

export const PASSWORD_CHANGE_REQUESTED = "PASSWORD_CHANGE_REQUESTED";
export const PASSWORD_CHANGE_SUCCEEDED = "PASSWORD_CHANGE_SUCCEEDED";

export const TOKEN_VERIFICATION_REQUESTED = "TOKEN_VERIFICATION_REQUESTED";
export const TOKEN_VERIFICATION_SUCCEEDED = "TOKEN_VERIFICATION_SUCCEEDED";

export const ACCOUNT_ACTIVATION_REQUESTED = "ACCOUNT_ACTIVATION_REQUESTED";
export const ACCOUNT_ACTIVATION_SUCCEEDED = "ACCOUNT_ACTIVATION_SUCCEEDED";

export const RESEND_ACTIVATION_TOKEN_REQUESTED =
  "RESEND_ACTIVATION_TOKEN_REQUESTED";
export const RESEND_ACTIVATION_TOKEN_SUCCEEDED =
  "RESEND_ACTIVATION_TOKEN_SUCCEEDED";

export const VALIDATE_ACCESS_TOKEN_REQUESTED =
  "VALIDATE_ACCESS_TOKEN_REQUESTED";
export const VALIDATE_ACCESS_TOKEN_SUCCEEDED =
  "VALIDATE_ACCESS_TOKEN_SUCCEEDED";
export const FORCE_LOGIN = "FORCE_LOGIN";

export const OAUTH_REQUESTED = "OAUTH_REQUESTED";
export const OAUTH_SUCCEEDED = "OAUTH_SUCCEEDED";
export const OAUTH_FAILED = "OAUTH_FAILED";

/**
 * Matches
 */
export const CHECK_INITAL_FILTER_REQUESTED = "CHECK_INITAL_FILTER_REQUESTED";
export const TOGGLE_MODAL_REQUESTED = "TOGGLE_MODAL_REQUESTED";
export const TOGGLE_MODAL_SUCCEEDED = "TOGGLE_MODAL_SUCCEEDED";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const SET_RATING = "SET_RATING";
export const RATING_SET = "RATING_SET";

export const MATCH_ACTION_REQUESTED = "MATCH_ACTION_REQUESTED";
export const MATCH_ACTION_SUCCEEDED = "MATCH_ACTION_SUCCEEDED";

export const FAVORITES_COUNT_REQUESTED = "FAVORITES_COUNT_REQUESTED";
export const FAVORITES_COUNT_SUCCEEDED = "FAVORITES_COUNT_SUCCEEDED";
export const FAVORITES_COUNT_FAILED = "FAVORITES_COUNT_FAILED";

export const SEARCH_TEXT_REQUESTED = "SEARCH_TEXT_REQUESTED";
export const SET_LOAD_INITIAL_FACETS = "SET_LOAD_INITIAL_FACETS";
export const SET_RECALCULATE_INITIAL_FACETS_COUNT =
  "SET_RECALCULATE_INITIAL_FACETS_COUNT";

export const DATA_FILTER_REQUESTED = "DATA_FILTER_REQUESTED";
export const DATA_REQUESTED = "DATA_REQUESTED";
export const DATA_LOADED = "DATA_LOADED";
export const FACETS_RELOADED = "FACETS_RELOADED";
export const SET_INITIAL_FACETS = "SET_INITIAL_FACETS";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const PAGE_CHANGED = "PAGE_CHANGED";

export const SET_QUERY_FILTER = "SET_QUERY_FILTER";

export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const REMOVE_FILTER_LIST = "REMOVE_FILTER_LIST";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const TOGGLE_SECTION = "TOGGLE_SECTION";
export const SET_ORDER = "SET_ORDER";

export const MATCHING_PROGRESS_REQUESTED = "MATCHING_PROGRESS_REQUESTED";
export const MATCHING_PROGRESS_SUCCEEDED = "MATCHING_PROGRESS_SUCCEEDED";
export const MATCHING_PROGRESS_FAILED = "MATCHING_PROGRESS_FAILED";

/**
 * Talent profile
 */

export const SAVE_PROFILE_REQUESTED = "SAVE_PROFILE_REQUESTED";
export const SAVE_PROFILE_SUCCEEDED = "SAVE_PROFILE_SUCCEEDED";

export const LOAD_PROFILE_REQUESTED = "LOAD_PROFILE_REQUESTED";
export const LOAD_PROFILE_SUCCEEDED = "LOAD_PROFILE_SUCCEEDED";

export const SAVE_PARTIAL_TALENT_REQUESTED = "SAVE_PARTIAL_TALENT_REQUESTED";
export const SAVE_PARTIAL_TALENT_SUCCEEDED = "SAVE_PARTIAL_TALENT_SUCCEEDED";

export const SAVE_PERSONAL_INFO_REQUESTED = "SAVE_PERSONAL_INFO_REQUESTED";
export const SAVE_PERSONAL_INFO_SUCCEEDED = "SAVE_PERSONAL_INFO_SUCCEEDED";

export const SAVE_PROFFESIONAL_PROFILE_REQUESTED =
  "SAVE_PROFFESIONAL_PROFILE_REQUESTED";
export const SAVE_PROFFESIONAL_PROFILE_SUCCEEDED =
  "SAVE_PROFFESIONAL_PROFILE_SUCCEEDED";

export const SAVE_WORK_PREFERENCE_REQUESTED = "SAVE_WORK_PREFERENCE_REQUESTED";
export const SAVE_WORK_PREFERENCE_SUCCEEDED = "SAVE_WORK_PREFERENCE_SUCCEEDED";

export const SAVE_COMMUNICATION_PREFERENCE_REQUESTED =
  "SAVE_COMMUNICATION_PREFERENCE_REQUESTED";
export const SAVE_COMMUNICATION_PREFERENCE_SUCCEEDED =
  "SAVE_COMMUNICATION_PREFERENCE_SUCCEEDED";

export const ADD_STRIPE_CUSTOMER_TAX_ID_REQUESTED =
  "ADD_STRIPE_CUSTOMER_TAX_ID_REQUESTED";
export const ADD_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED =
  "ADD_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED";
export const ADD_STRIPE_CUSTOMER_TAX_ID_FAILED =
  "ADD_STRIPE_CUSTOMER_TAX_ID_FAILED";

export const DELETE_STRIPE_CUSTOMER_TAX_ID_REQUESTED =
  "DELETE_STRIPE_CUSTOMER_TAX_ID_REQUESTED";
export const DELETE_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED =
  "DELETE_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED";
export const DELETE_STRIPE_CUSTOMER_TAX_ID_FAILED =
  "DELETE_STRIPE_CUSTOMER_TAX_ID_FAILED";

export const COPY_TALENT_ADDRESS_INTO_CUSTOMER_ADDRESS =
  "COPY_TALENT_ADDRESS_INTO_CUSTOMER_ADDRESS";
export const OPEN_CUSTOMER_FORM = "OPEN_CUSTOMER_FORM";
export const CLOSE_CUSTOMER_FORM = "CLOSE_CUSTOMER_FORM";

export const CUSTOMER_PAYMENT_METHODS_REQUESTED =
  "CUSTOMER_PAYMENT_METHODS_REQUESTED";
export const CUSTOMER_PAYMENT_METHODS_LOADED =
  "CUSTOMER_PAYMENT_METHODS_LOADED";
export const CUSTOMER_PAYMENT_METHODS_FAILED =
  "CUSTOMER_PAYMENT_METHODS_FAILED";

export const DELETE_ACCOUNT_REQUESTED = "DELETE_ACCOUNT_REQUESTED";
export const DELETE_ACCOUNT_SUCCEEDED = "DELETE_ACCOUNT_SUCCEEDED";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";

export const MATCHING_TALENT_REQUESTED = "MATCHING_TALENT_REQUESTED";
export const MATCHING_TALENT_SUCCEEDED = "MATCHING_TALENT_SUCCEEDED";
export const MATCHING_TALENT_FAILED = "MATCHING_TALENT_FAILED";

export const CATEGORY_JOBS_PREVIEW_REQUESTED =
  "CATEGORY_JOBS_PREVIEW_REQUESTED";
export const CATEGORY_JOBS_PREVIEW_SUCCEEDED =
  "CATEGORY_JOBS_PREVIEW_SUCCEEDED";
export const CATEGORY_JOBS_PREVIEW_FAILED = "CATEGORY_JOBS_PREVIEW_FAILED";
export const SUBCATEGORY_PREVIEW_CHANGED = "SUBCATEGORY_PREVIEW_CHANGED";

/**
 * Subscriptions
 */
export const LOAD_SUBSCRIPTION_SUCCEEDED = "LOAD_SUBSCRIPTION_SUCCEEDED";
export const LOAD_SUBSCRIPTION_FAILED = "LOAD_SUBSCRIPTION_FAILED";

export const LOAD_SUBSCRIPTION_ID_REQUESTED = "LOAD_SUBSCRIPTION_ID_REQUESTED";
export const LOAD_SUBSCRIPTION_ID_SUCCEEDED = "LOAD_SUBSCRIPTION_ID_SUCCEEDED";
export const LOAD_SUBSCRIPTION_ID_FAILED = "LOAD_SUBSCRIPTION_ID_FAILED";

export const PLANS_REQUESTED = "PLANS_REQUESTED";
export const PLANS_LOADED = "PLANS_LOADED";

export const LOAD_BANNER_REQUESTED = "LOAD_BANNER_REQUESTED";
export const LOAD_BANNER_SUCCEEDED = "LOAD_BANNER_SUCCEEDED";
export const LOAD_BANNER_FAILED = "LOAD_BANNER_FAILED";

export const CREATE_SUBSCRIPTION_REQUESTED = "CREATE_SUBSCRIPTION_REQUESTED";
export const CREATE_SUBSCRIPTION_SUCCEEDED = "CREATE_SUBSCRIPTION_SUCCEEDED";
export const CREATE_SUBSCRIPTION_FAILED = "CREATE_SUBSCRIPTION_FAILED";

export const SUBSCRIPTION_PLAN_SELECTED = "SUBSCRIPTION_PLAN_SELECTED";
export const SUBSCRIPTION_PAYMENT_METHOD_SELECTED =
  "SUBSCRIPTION_PAYMENT_METHOD_SELECTED";

export const STORE_PAYMENT_METHOD_REQUESTED = "STORE_PAYMENT_METHOD_REQUESTED";
export const STORE_PAYMENT_METHOD_SUCCEEDED = "STORE_PAYMENT_METHOD_SUCCEEDED";
export const STORE_PAYMENT_METHOD_FAILED = "STORE_PAYMENT_METHOD_FAILED";

export const TALENT_CURRENT_SUBSCRIPTION_REQUESTED =
  "TALENT_CURRENT_SUBSCRIPTION_REQUESTED";
export const TALENT_CURRENT_SUBSCRIPTION_LOADED =
  "TALENT_CURRENT_SUBSCRIPTION_LOADED";
export const TALENT_CURRENT_SUBSCRIPTION_FAILED =
  "TALENT_CURRENT_SUBSCRIPTION_FAILED";

export const SUBSCRIPTION_CANCELATION_REQUESTED =
  "SUBSCRIPTION_CANCELATION_REQUESTED";
export const SUBSCRIPTION_CANCELATION_SUCCEEDED =
  "SUBSCRIPTION_CANCELATION_SUCCEEDED";
export const SUBSCRIPTION_CANCELATION_FAILED =
  "SUBSCRIPTION_CANCELATION_FAILED";

export const LOAD_STRIPE_CUSTOMER_REQUESTED = "LOAD_STRIPE_CUSTOMER_REQUESTED";
export const LOAD_STRIPE_CUSTOMER_SUCCEEDED = "LOAD_STRIPE_CUSTOMER_SUCCEEDED";
export const LOAD_STRIPE_CUSTOMER_FAILED = "LOAD_STRIPE_CUSTOMER_FAILED";

export const UPDATE_STRIPE_CUSTOMER_REQUESTED =
  "UPDATE_STRIPE_CUSTOMER_REQUESTED";
export const UPDATE_STRIPE_CUSTOMER_SUCCEEDED =
  "UPDATE_STRIPE_CUSTOMER_SUCCEEDED";
export const UPDATE_STRIPE_CUSTOMER_FAILED = "UPDATE_STRIPE_CUSTOMER_FAILED";

export const SUBSCRIPTION_COUPON_REQUESTED = "SUBSCRIPTION_COUPON_REQUESTED";
export const SUBSCRIPTION_COUPON_SUCCEEDED = "SUBSCRIPTION_COUPON_SUCCEEDED";
export const SUBSCRIPTION_COUPON_FAILED = "SUBSCRIPTION_COUPON_FAILED";

export const SET_CURRENT_STATUS = "SET_CURRENT_STATUS";
export const SET_PERCENTAGE_COMPLETION = "SET_PERCENTAGE_COMPLETION";

export const REMOVE_CARD_REQUESTED = "REMOVE_CARD_REQUESTED";

export const PLAN_CHANGE_OPTIONS_REQUESTED = "PLAN_CHANGE_OPTIONS_REQUESTED";
export const PLAN_CHANGE_OPTIONS_SUCCEEDED = "PLAN_CHANGE_OPTIONS_SUCCEEDED";
export const PLAN_CHANGE_OPTIONS_FAILED = "PLAN_CHANGE_OPTIONS_FAILED";

export const PLAN_CHANGE_REQUESTED = "PLAN_CHANGE_REQUESTED";
export const PLAN_CHANGE_SUCCEEDED = "PLAN_CHANGE_SUCCEEDED";
export const PLAN_CHANGE_FAILED = "PLAN_CHANGE_FAILED";

export const PLAN_REQUESTED = "PLAN_REQUESTED";
export const PLAN_SUCCEEDED = "PLAN_SUCCEEDED";
export const PLAN_FAILED = "PLAN_FAILED";

export const PREVIEW_MATCHES_REQUESTED = "PREVIEW_MATCHES_REQUESTED";
export const PREVIEW_MATCHES_SUCCEEDED = "PREVIEW_MATCHES_SUCCEEDED";
export const PREVIEW_MATCHES_FAILED = "PREVIEW_MATCHES_FAILED";

export const ALL_SKILLS_REQUESTED = "ALL_SKILLS_REQUESTED";
export const ALL_SKILLS_LOADED = "ALL_SKILLS_LOADED";
export const ALL_SKILLS_FAILED = "ALL_SKILLS_FAILED";

export const SAVE_SKILL_REQUESTED = "SAVE_SKILL_REQUESTED";
export const SAVE_SKILL_SUCCEEDED = "SAVE_SKILL_SUCCEEDED";
export const SAVE_SKILL_FAILED = "SAVE_SKILL_FAILED";
