import axios from "axios";
import { API_URL } from "../config";
import { WISAR_TOKEN_KEY } from "../config";

const CONNECTION_ERROR = {
  message:
    "There is an error establishing connection to the server, Please try again later.",
};

export const AUTHORIZATION_ERROR = {
  message: "You are not authorized to perform this action.",
};

const tokenHeader = () => {
  const tokenFromStorage = localStorage.getItem(WISAR_TOKEN_KEY) || "";
  return `Bearer ${tokenFromStorage}`;
};
export function api_get(path, params) {
  const url = `${API_URL}${path}`;
  return axios({
    method: "get",
    url,
    params,
    withCredentials: true,
    crossDomain: true,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        return error.response.data;

        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        throw CONNECTION_ERROR;
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
      }
      return error;
    });
}

export function api_post(path, data, params) {
  const url = `${API_URL}${path}`;
  return axios({
    method: "post",
    url,
    data,
    params,
    crossDomain: true,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenHeader(),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        throw CONNECTION_ERROR;
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return error;
    });
}

export function api_post_authorization(path, data, params) {
  const url = `${API_URL}${path}`;

  return axios({
    method: "post",
    url,
    data,
    params,
    crossDomain: true,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenHeader(),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        throw CONNECTION_ERROR;
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return error;
    });
}
export function api_put(path, data, params) {
  const url = `${API_URL}${path}`;
  return axios({
    method: "put",
    url,
    data,
    params,
    crossDomain: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenHeader(),
    },
  })
    .then((response) => {
      if (response.status === 401) {
        throw AUTHORIZATION_ERROR;
      }
      return response.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //  console.log(error.response.data);
        if (error.response.status === 401) {
          //console.log('unauthorized, logging out ...');
          throw error.response;
        }
        return error.response.data;

        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        //  console.log(error.request);
        throw CONNECTION_ERROR;
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
      }

      return error;
    });
}

export function api_delete(path, data, params) {
  const url = `${API_URL}${path}`;
  return axios({
    method: "delete",
    url,
    data,
    params,
    crossDomain: true,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: tokenHeader(),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        return error.response.data;

        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        throw CONNECTION_ERROR;
      } else {
        // Something happened in setting up the request that triggered an Error
      }
      return error;
    });
}
