import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/bootstrap.min.css";
import "./assets/js/bootstrap-select/css/bootstrap-select.min.css";
import "./assets/css/wisar.min.css";
import ReactModal from "react-modal";
import AlertModal from "./components/modals/alert-modal";

import Header from "./components/header/header";
import Footer from "./components/footer/index";
import Routes from "./routes";
import { showModal, hideModal } from "./actions/modals.actions";
import { validateAccessToken } from "./actions/access.actions";
import { loadTalent } from "./actions/talent-profile.actions";
import { loadSubscriptionById } from "./actions/subscription.actions";

import { resetMessage } from "./actions/messages.actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addActionCount } from "./actions/matches.actions";

import { WISAR_TOKEN_KEY } from "./config";
import loading_icon from "./assets/img/loading.gif";

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  loadSubscriptionById: () => dispatch(loadSubscriptionById()),
  showModal: (payload) => {
    dispatch(showModal(payload));
  },
  loadUserFromToken: () => {
    let token = localStorage.getItem(WISAR_TOKEN_KEY);
    if (!token || token === "") {
      //if there is no token, dont bother
      return;
    }
    //fetch user from token (if server deems it’s valid token)
    dispatch(validateAccessToken(token));
  },
  resetMessage: () => dispatch(resetMessage()),
  addActionCount: (match_id, click_action) =>
    dispatch(addActionCount(match_id, click_action)),
  loadTalent: () => dispatch(loadTalent()),
});

function mapStateToProps(state) {
  return {
    isShowingModal: state.modalReducers.modalProps.open,
    modalIsOpen: state.modalReducers.modalProps.open,
    modalProps: state.modalReducers.modalProps,
    modalType: state.modalReducers.modalType,
    display_message: state.messagesReducers.display_message, // Toast notification message
    error_message: state.messagesReducers.error_message, // Toast notification message
    loading: state.loadingReducer.loading, // overlay loading (for subscription creation after login)
    loading_text: state.loadingReducer.text,
  };
}
const notify = (message) => {
  if (message) {
    toast.info(message);
  }
};
const notifyError = (message) => {
  if (message) {
    toast.error(message);
  }
};
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      error: null,
      errorInfo: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.increaseJumpCount = this.increaseJumpCount.bind(this);

    // this.openAlertModal = this.openAlertModal.bind(this);
    this.props.loadUserFromToken();
    this.props.loadSubscriptionById();
    this.props.loadTalent();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  closeModal() {
    this.props.hideModal();
  }
  increaseJumpCount() {
    this.props.addActionCount(
      this.props.modalProps.match_id,
      "navigation_jump_clicks"
    );
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        border: null,
        padding: "0px",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "1.7",
        zIndex: 1055,
        backgroundColor: "",
      },
      overlay: {
        backgroundColor: "rgba(99, 90, 90, 0.75)",
        zIndex: 1054,
      },
    };

    const { error, errorInfo } = this.state;
    if (this.props.display_message) {
      notify(this.props.display_message);
      setTimeout(() => {
        this.props.resetMessage();
      }, 5000);
    }
    if (this.props.error_message) {
      notifyError(this.props.error_message);
      setTimeout(() => {
        this.props.resetMessage();
      }, 5000);
    }
    return (
      <div>
        <Header />

        <ToastContainer />

        {!error && <Routes />}

        {error && (
          <div className="App__error container">
            <div role="alert" className="alert alert-danger">
              <h4>An error occurred. Please reload the page and try again.</h4>
              <p className="App__stacktrace">
                {process.env.REACT_APP_NODE_ENV === "development" &&
                  errorInfo.componentStack}
              </p>
            </div>
          </div>
        )}

        <Footer />
        {this.props.isShowingModal && (
          <ReactModal
            isOpen={this.props.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            style={customStyles}
            portalClassName={"modal_overlay"}
          >
            <AlertModal
              increaseJumpCount={(e) => this.increaseJumpCount()}
              closeModal={(e) => this.closeModal()}
              title={this.props.modalProps.title}
              message={this.props.modalProps.message}
              link={this.props.modalProps.link}
            ></AlertModal>
          </ReactModal>
        )}
        {this.props.loading && (
          <ReactModal
            isOpen={this.props.loading}
            ariaHideApp={false}
            style={customStyles}
            portalClassName={"modal_overlay"}
          >
            <img
              className="loadingIcon"
              src={loading_icon}
              alt="loading"
              width="20%"
            />
            <p className="loading-text">{this.props.loading_text}</p>
          </ReactModal>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
