import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as ActionTypes from "../constants/actionTypes";
import * as messages from "../constants/messages";
import { api_get, api_put, api_post, api_delete } from "../utils/api";
import Cookies from "js-cookie";
import {
  COOKIE_USER,
  WISAR_SUBSCRIPTION_STATUS_KEY,
  WISAR_SUBSCRIPTION_KEY,
} from "../config";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Talent profile information
 */
export function* watcherSaveTalentProfileSaga() {
  yield takeEvery(ActionTypes.SAVE_PROFILE_REQUESTED, saveTalent);
}

function* saveTalent() {
  try {
    const response = yield call(api_put, `/api/v1/talents`);
    if (response.error) throw response;
    yield put({
      type: ActionTypes.SAVE_PROFILE_SUCCEEDED,
      talent: response.talent,
    });
  } catch (e) {
    yield put({ type: ActionTypes.API_ERRORED, payload: e });
  }
}
/**
 * Loads profile when user enters to profile page
 */
export function* watcherLoadTalentProfileSaga() {
  yield takeEvery(ActionTypes.LOAD_PROFILE_REQUESTED, loadTalent);
}

function* loadTalent() {
  let u = Cookies.getJSON(COOKIE_USER);
  let user_id = u ? u._id : null;
  if (!user_id) {
    // No user logged in or subscription not completed
    // yield put(push('/'))
    // yield put({ type: ActionTypes.FORCE_LOGIN });
    return;
  } else {
    try {
      const response = yield call(api_get, `/api/v1/talents/users/${user_id}`);
      if (response.error) throw response;
      if (u) {
        u.talent_id = response.talent ? response.talent._id : null;
        const inOneWeek = new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 7
        );
        Cookies.set(COOKIE_USER, u, { expires: inOneWeek, samesite: "strict" });
      }
      yield put({
        type: ActionTypes.LOAD_PROFILE_SUCCEEDED,
        talent: response.talent,
        customer: response.customer,
      });
      if (response.customer && response.customer.id) {
        yield put({
          type: ActionTypes.CUSTOMER_PAYMENT_METHODS_REQUESTED,
          customer_id: response.customer.id,
        });
      }
    } catch (e) {
      yield put({ type: ActionTypes.API_ERRORED, payload: e });
    }
  }
}

/**
 * Updates personal information
 */
function* savePartialTalent(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;
    const response = yield call(
      api_put,
      `/api/v1/talents/${talent_id}`,
      action.payload
    );

    if (response.error) {
      throw response;
    } else {
      yield put({
        type: ActionTypes.SAVE_PARTIAL_TALENT_SUCCEEDED,
        talent: response.talent,
        form: action.form_name,
      });
      if (action.showDisplayMessage) {
        yield put({
          type: ActionTypes.SET_INFO_MESSAGE,
          message: messages.SUCCEEDED_GENERAL_MESSAGE,
        });
      }
      if (response.customer) {
        yield put({
          type: ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED,
          customer: response.customer,
        });
      }
      if (response.display_message) {
        yield put({
          type: ActionTypes.SET_INFO_MESSAGE,
          message: response.display_message,
        });
      }
    }
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.API_ERRORED, error: e });
    }
  }
}
export function* watcherUpdateTalentSaga() {
  yield takeEvery(ActionTypes.SAVE_PARTIAL_TALENT_REQUESTED, savePartialTalent);
}

///talents/run/
/**
 * Updates personal information
 */
function* runMatchingForTalent(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;
    const response = yield call(
      api_get,
      `/api/v1/talents/matching/${talent_id}`
    );
    if (response.error) {
      throw response;
    } else {
      yield put({ type: ActionTypes.MATCHING_TALENT_SUCCEEDED });

      /* Removed 01/10/2020           
               if (response.display_message) {
                            yield put({ type: ActionTypes.SET_INFO_MESSAGE, message: response.display_message });
                        } */
    }
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.MATCHING_TALENT_FAILED, error: e });
    }
  }
}
export function* watcherRunMatchingForTalentSaga() {
  yield takeEvery(ActionTypes.MATCHING_TALENT_REQUESTED, runMatchingForTalent);
}

/**
 * Updates personal information
 */
function* savePersonalInfo(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;

    const response = yield call(
      api_put,
      `/api/v1/talents/${talent_id}`,
      action.payload
    );
    if (response.error) {
      throw response;
    } else {
      yield put({
        type: ActionTypes.SAVE_PERSONAL_INFO_SUCCEEDED,
        talent: response.talent,
      });
      yield put({
        type: ActionTypes.SET_INFO_MESSAGE,
        message: messages.SUCCEEDED_GENERAL_MESSAGE,
      });
      if (response.customer) {
        yield put({
          type: ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED,
          customer: response.customer,
        });
      }
      if (response.display_message) {
        yield put({
          type: ActionTypes.SET_INFO_MESSAGE,
          message: response.display_message,
        });
      }
    }
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.API_ERRORED, error: e });
    }
  }
}
export function* watcherSavePersonalInfoSaga() {
  yield takeEvery(ActionTypes.SAVE_PERSONAL_INFO_REQUESTED, savePersonalInfo);
}
/**
 * Updates professional profile
 */
function* saveProfessionalProfile(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;
    const response = yield call(
      api_put,
      `/api/v1/talents/${talent_id}`,
      action.payload
    );
    if (response.error) throw response;
    yield put({
      type: ActionTypes.SAVE_PROFFESIONAL_PROFILE_SUCCEEDED,
      talent: response.talent,
    });
    yield put({
      type: ActionTypes.SET_INFO_MESSAGE,
      message: messages.SUCCEEDED_GENERAL_MESSAGE,
    });
    if (response.display_message) {
      yield put({
        type: ActionTypes.SET_INFO_MESSAGE,
        message: response.display_message,
      });
    }
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.API_ERRORED, error: e });
    }
  }
}
export function* watcherSaveProfessionalProfileSaga() {
  yield takeEvery(
    ActionTypes.SAVE_PROFFESIONAL_PROFILE_REQUESTED,
    saveProfessionalProfile
  );
}

/**
 * Save work preference
 */
function* saveWorkPreference(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;

    const response = yield call(
      api_put,
      `/api/v1/talents/${talent_id}`,
      action.payload
    );
    if (response.error) throw response;
    yield put({
      type: ActionTypes.SAVE_WORK_PREFERENCE_SUCCEEDED,
      talent: response.talent,
    });
    yield put({
      type: ActionTypes.SET_INFO_MESSAGE,
      message: messages.SUCCEEDED_GENERAL_MESSAGE,
    });
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.API_ERRORED, error: e });
    }
  }
}

export function* watcherSaveWorkPreferencesSaga() {
  yield takeEvery(
    ActionTypes.SAVE_WORK_PREFERENCE_REQUESTED,
    saveWorkPreference
  );
}
/**
 * Upsates Communication preference
 */
function* saveCommunicationPreference(action) {
  try {
    let u = Cookies.getJSON(COOKIE_USER);
    let talent_id = u.talent_id;

    const response = yield call(
      api_put,
      `/api/v1/talents/${talent_id}`,
      action.payload
    );
    if (response.error) throw response;
    yield put({
      type: ActionTypes.SAVE_COMMUNICATION_PREFERENCE_SUCCEEDED,
      talent: response.talent,
    });
    yield put({
      type: ActionTypes.SET_INFO_MESSAGE,
      message: messages.SUCCEEDED_GENERAL_MESSAGE,
    });
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.API_ERRORED, error: e });
    }
  }
}
export function* watcherSaveCommunicationPreferenceSaga() {
  yield takeEvery(
    ActionTypes.SAVE_COMMUNICATION_PREFERENCE_REQUESTED,
    saveCommunicationPreference
  );
}
/**
 * Loads stripe customer's info with subscription and taxes [latest api version]
 */
function* loadCustomerByTalentId(action) {
  const user = Cookies.getJSON(COOKIE_USER);

  if (!user || !user.talent_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
  }
  try {
    const response = yield call(
      api_get,
      `/api/v1/talents/${user.talent_id}/customer`
    );
    if (response.error) throw response;
    yield put({
      type: ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED,
      customer: response.customer,
    });
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.LOAD_STRIPE_CUSTOMER_FAILED, error: e });
    }
  }
}
export function* watcherLoadCustomerByTalentIdSaga() {
  yield takeEvery(
    ActionTypes.LOAD_STRIPE_CUSTOMER_REQUESTED,
    loadCustomerByTalentId
  );
}

/**
 * Updates Customer's Information on Stripe
 * @param {*} action
 */
function* updateCustomer(action) {
  const { customer_id, customer } = action;

  try {
    const response = yield call(
      api_put,
      `/api/v1/stripe/customer/${customer_id}`,
      customer
    );
    if (response.error) {
      yield put({
        type: ActionTypes.UPDATE_STRIPE_CUSTOMER_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_STRIPE_CUSTOMER_SUCCEEDED,
        customer: response.customer,
      });
      yield put({
        type: ActionTypes.SET_INFO_MESSAGE,
        message: messages.SUCCEEDED_GENERAL_MESSAGE,
      });
    }
  } catch (e) {
    if (e.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.UPDATE_STRIPE_CUSTOMER_FAILED, error: e });
    }
  }
}
export function* watcherUpdateCustomerSaga() {
  yield takeEvery(ActionTypes.UPDATE_STRIPE_CUSTOMER_REQUESTED, updateCustomer);
}

/**
 * Creates a Customer's Tax Id on Stripe
 * @param {*} action
 */
function* addCustomerTaxId(action) {
  const { customer_id, tax_id, tax_type } = action;

  try {
    const response = yield call(
      api_post,
      `/api/v1/stripe/customer/taxes/${customer_id}`,
      { tax_id, tax_type }
    );
    if (response.error) {
      yield put({
        type: ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED,
        customer: response.customer,
        message: response.message,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({
        type: ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_FAILED,
        error: error,
      });
    }
  }
}
export function* watcherAddCustomerTaxIdSaga() {
  yield takeEvery(
    ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_REQUESTED,
    addCustomerTaxId
  );
}
/**
 * Removes a Customer's Tax Id on Stripe
 */
function* deleteCustomerTaxId(action) {
  const { customer_id, id } = action;
  try {
    const response = yield call(
      api_put,
      `/api/v1/stripe/customer/taxes/${customer_id}`,
      { id }
    );

    if (response.error) {
      yield put({
        type: ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED,
        customer: response.customer,
        message: response.message,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({
        type: ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_FAILED,
        error,
      });
    }
  }
}
export function* watcherDeleteCustomerTaxIdSaga() {
  yield takeEvery(
    ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_REQUESTED,
    deleteCustomerTaxId
  );
}
/**
 * Cancel subscripton
 * @param {*} action
 */
function* cancelSubscription(action) {
  try {
    const { subscription_id, customer_id } = action;
    let user = Cookies.getJSON(COOKIE_USER);
    let user_id = user ? user._id : "";

    let url = `/api/v1/subscriptions/cancel/${subscription_id}`;

    const response = yield call(api_post, url, { customer_id, user_id });

    if (response.error) {
      yield put({
        type: ActionTypes.SUBSCRIPTION_CANCELATION_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.SUBSCRIPTION_CANCELATION_SUCCEEDED,
        subscription: response.subscription,
        message: response.message,
      });
      if (response.subscription) {
        yield put({
          type: ActionTypes.SET_CURRENT_STATUS,
          current_status: response.subscription.status,
        });
        localStorage.setItem(
          WISAR_SUBSCRIPTION_STATUS_KEY,
          response.subscription.status
        );
        if (response.subscription.status === "canceled") {
          yield put(push("/subscription"));
          localStorage.removeItem(WISAR_SUBSCRIPTION_KEY);
        }
      }
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.SUBSCRIPTION_CANCELATION_FAILED, error });
    }
  }
}
export function* watcherCancelSubscriptionSaga() {
  yield takeEvery(
    ActionTypes.SUBSCRIPTION_CANCELATION_REQUESTED,
    cancelSubscription
  );
}
export function* watcherGetCustomerPaymentMethodSaga() {
  yield takeEvery(
    ActionTypes.CUSTOMER_PAYMENT_METHODS_REQUESTED,
    getCustomerPaymentMethods
  );
}
/**
 *
 * @param {*} action
 */
function* getCustomerPaymentMethods(action) {
  const { customer_id } = action;
  try {
    const response = yield call(
      api_get,
      `/api/v1/stripe/customer/payment_methods/${customer_id}`
    );
    if (response.error) {
      yield put({
        type: ActionTypes.CUSTOMER_PAYMENT_METHODS_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.CUSTOMER_PAYMENT_METHODS_LOADED,
        payment_methods: response.payment_methods,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.CUSTOMER_PAYMENT_METHODS_FAILED, error });
    }
  }
}

export function* watcherDeleteAccountSaga() {
  yield takeEvery(ActionTypes.DELETE_ACCOUNT_REQUESTED, deleteAccount);
}
/**
* Removes all user data, cancel subscriptions, etc.

* @param {*} action 
*/
function* deleteAccount(action) {
  const user = Cookies.getJSON(COOKIE_USER);

  if (!user || !user.talent_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
  }
  try {
    const response = yield call(
      api_delete,
      `/api/v1/talents/${user.talent_id}`
    );
    if (response.error) {
      yield put({
        type: ActionTypes.DELETE_ACCOUNT_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.DELETE_ACCOUNT_SUCCEEDED,
        message: response.message,
      });
      yield delay(3000);
      yield put({ type: ActionTypes.FORCE_LOGIN });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.DELETE_ACCOUNT_FAILED, error });
    }
  }
}
/**
 * Applies a coupon to a subscripton
 * @param {*} action
 */
function* applyCouponToSubscription(action) {
  try {
    const { subscription_id, coupon_id } = action;
    yield put({
      type: ActionTypes.SET_LOADING,
      payload: { scope: "subscription", loading: true },
    });

    const response = yield call(
      api_post,
      `/api/v1/subscriptions/coupons/${subscription_id}`,
      { coupon_id }
    );
    yield put({
      type: ActionTypes.SET_LOADING,
      payload: { scope: "subscription", loading: false },
    });

    if (response.error) {
      yield put({
        type: ActionTypes.SUBSCRIPTION_COUPON_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.SUBSCRIPTION_COUPON_SUCCEEDED,
        subscription: response.subscription,
        message: response.message,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.SUBSCRIPTION_COUPON_FAILED, error });
    }
  }
}
export function* watcherApplyCouponToSubscriptionSaga() {
  yield takeEvery(
    ActionTypes.SUBSCRIPTION_COUPON_REQUESTED,
    applyCouponToSubscription
  );
}

/**
 * Find jobs by category id
 * @param {*} action
 */
function* loadCategoryPreviewJobs(action) {
  try {
    const { categories } = action;
    if (!categories) {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_SUCCEEDED,
        jobs: [],
        count: 0,
      });
      return;
    }
    const response = yield call(api_put, `/api/v1/jobs/parent-category`, {
      limit: 15,
      categories: categories,
    });
    if (response.error) {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_SUCCEEDED,
        jobs: response.jobs,
        count: response.count,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.SUBSCRIPTION_COUPON_FAILED, error });
    }
  }
}
export function* watcherLoadCategoryJobPreviewSaga() {
  yield takeEvery(
    ActionTypes.CATEGORY_JOBS_PREVIEW_REQUESTED,
    loadCategoryPreviewJobs
  );
}

/**
 * Find jobs by category id
 * @param {*} action
 */
function* filterPreviewJobs(action) {
  try {
    const { categories } = action;

    if (!categories) {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_SUCCEEDED,
        jobs: [],
        count: 0,
      });
      return;
    }
    const response = yield call(api_put, "/api/v1/jobs/categories", {
      limit: 10,
      categories: categories,
    });
    if (response.error) {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_FAILED,
        error: response.error,
      });
    } else {
      yield put({
        type: ActionTypes.CATEGORY_JOBS_PREVIEW_SUCCEEDED,
        jobs: response.jobs,
        count: response.count,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.SUBSCRIPTION_COUPON_FAILED, error });
    }
  }
}
export function* watcherFilterCategoryJobPreviewSaga() {
  yield takeLatest(ActionTypes.SUBCATEGORY_PREVIEW_CHANGED, filterPreviewJobs);
}

/**
 * Find jobs by category id
 * @param {*} action
 */
function* createTalentMatchesForCategories(action) {
  const user = Cookies.getJSON(COOKIE_USER);
  if (!user || !user.talent_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
  }
  try {
    const { categories } = action;

    if (!categories) {
      yield put({ type: ActionTypes.PREVIEW_MATCHES_REQUESTED });
      return;
    }
    const response = yield call(
      api_put,
      `/api/v1/jobs/categories/talent_id/${user.talent_id}`,
      { categories: categories }
    );
    if (response.error) {
      yield put({
        type: ActionTypes.PREVIEW_MATCHES_FAILED,
        error: response.error,
      });
    } else {
      // yield put({ type: ActionTypes.SET_INFO_MESSAGE, message: messages.SUCCEEDED_MATCHES_CREATION});

      yield put({
        type: ActionTypes.PREVIEW_MATCHES_SUCCEEDED,
        preview_quantity: response.qty,
      });
    }
  } catch (error) {
    if (error.status === 401) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
    } else {
      yield put({ type: ActionTypes.PREVIEW_MATCHES_FAILED, error });
    }
  }
}
export function* watcherCreateTalentMatchesForCategorieswSaga() {
  yield takeEvery(
    ActionTypes.PREVIEW_MATCHES_REQUESTED,
    createTalentMatchesForCategories
  );
}

/**
 * Load Skills
 */
export function* watcherRequestAllSkillsSaga() {
  yield takeEvery(ActionTypes.ALL_SKILLS_REQUESTED, getAllSkillsworkerSaga);
}

function* getAllSkillsworkerSaga() {
  try {
    const response = yield call(api_get, `/api/v1/skills`);

    yield put({
      type: ActionTypes.ALL_SKILLS_LOADED,
      all_skills: response.skills,
    });
  } catch (e) {
    yield put({ type: ActionTypes.API_ERRORED, payload: e });
  }
}
