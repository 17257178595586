import Cookies from 'js-cookie';
import * as ActionTypes from '../constants/actionTypes';
import { COOKIE_USER } from '../config';

const initialState = {
  user: {
    isAuthenticated: typeof Cookies.get(COOKIE_USER) !== 'undefined',
    loggedUserObj: Cookies.getJSON(COOKIE_USER),
  },
  current_user:null,
  token: null,
  error: null,
  oauth_error:null,
  talent_id: null,
  message: '',
  authenticated: false,
  isLoading: false,
  access_provider:"local",
  decoded_token:null

  /**
   * 
   * exp: 1612466937
iat: 1611257337
roles: (2) ["authenticated", "pro"]
subscription_id: "sub_IMPqb7m9R4yUeL"
subscription_status: "active"
talent_id: "1590976387599"
user_id: "5ed45f4d3ab2104584a8d81d"
   */
};

export default function access(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_INFO_MESSAGES:
      return Object.assign({}, state, {
        error:null,
        oauth_error:null,
        message: '',
        //isLoading:false
      });
    case ActionTypes.SET_JWT_OBJECT:
      return Object.assign({}, state, {
        decoded_token:action.decoded
      });
    case ActionTypes.ACCOUNT_ACTIVATION_REQUESTED:
    case ActionTypes.TOKEN_VERIFICATION_REQUESTED:
    case ActionTypes.PASSWORD_RESET_REQUESTED:
    case ActionTypes.PASSWORD_CHANGE_REQUESTED:
    case ActionTypes.SIGNUP_REQUESTED: 
    case ActionTypes.OAUTH_REQUESTED: 
    case ActionTypes.LOGIN_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true
      });
    
    case ActionTypes.OAUTH_SUCCEEDED:
    case ActionTypes.LOGIN_SUCCEEDED:
    case ActionTypes.VALIDATE_ACCESS_TOKEN_SUCCEEDED:
    case ActionTypes.PROFILE_SUCCEEDED: {
      return Object.assign({}, state, {
        user: {
          ...state.user,
          isAuthenticated: true,
          loggedUserObj: action.user,
        },
        isAuthenticated: true,
        current_user: action.user,
        token: action.token,
        talent_id: action.user && action.user.talent_id ? action.user.talent_id : null,
        error: null,
        oauth_error:null,
        isLoading: false,
        access_provider: action.access_provider
      });
    }
    case ActionTypes.OAUTH_FAILED:
      let err = null;
      if (action.error) {
        if (action.error.message) {
          err = action.error;
        }
        else if (action.error.error && action.error.error.message) {
          err = action.error.error.message;
        }
        else {
          err = action
        }
        return Object.assign({}, state, {
          oauth_error: err,
          message: '',
          isLoading: false
        });
      }
      
    case ActionTypes.PROFILE_FAILED:
    case ActionTypes.LOGIN_FAILED:
    case ActionTypes.SIGNUP_FAILED: {
      let err = null;
      if (action.error) {
        if (action.error.message) {
          err = action.error;
        }
        else if (action.error.error && action.error.error.message) {
          err = action.error.error;
        }
        else {
          err = action
        }
      }
      return Object.assign({}, state, {
        error: err,
        message: '',
        isLoading: false
      });
    }
    case ActionTypes.LOGIN_SUCCEEDED:
    case ActionTypes.SIGNUP_SUCCEEDED:
    case ActionTypes.RESEND_ACTIVATION_TOKEN_SUCCEEDED:
    case ActionTypes.ACCOUNT_ACTIVATION_SUCCEEDED:
    case ActionTypes.PASSWORD_RESET_SUCCEEDED:
    case ActionTypes.PASSWORD_CHANGE_SUCCEEDED:
    case ActionTypes.FORGOT_PASSWORD_SUCCEEDED: {
      return Object.assign({}, state, {
        message: action.message? action.message:'',
        error: null,
        isLoading: false,
      });
    }
    case ActionTypes.LOGOUT_REQUESTED: {
      return Object.assign({}, state, {
        user: {
          isAuthenticated: false,
          loggedUserObj: null,
        },
        token: null,
        error: null,
        talent_id: null,
        current_user: null,
        message: ''
      });
    }
    case ActionTypes.LOGOUT_SUCCEEDED: {
      return Object.assign({}, state, {
        user: {
          isAuthenticated: false,
          loggedUserObj: null,
        },
        token: null,
        error: null,
        talent_id: null,
        current_user: null,
        message: ''
      });
    }
    default:
      return state;
  }
}

