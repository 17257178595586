import * as ActionTypes from '../constants/actionTypes';


export const getSubscriptionPlans = () => ({
  type: ActionTypes.PLANS_REQUESTED,
});


export function selectPlan(plan) {
  return { type: ActionTypes.SUBSCRIPTION_PLAN_SELECTED, plan };
}
export function selectPaymentMethod(method){
  return { type: ActionTypes.SUBSCRIPTION_PAYMENT_METHOD_SELECTED, method };
}
export function createSubscription(plan_id, stripe_payment_method) {
  return { type: ActionTypes.CREATE_SUBSCRIPTION_REQUESTED, plan_id, stripe_payment_method };
}

export function savePaymentMethod(stripe_payment_method) {
  return { type: ActionTypes.STORE_PAYMENT_METHOD_REQUESTED, stripe_payment_method };
}

export const getTalentCurrentSubscription = () => ({
  type: ActionTypes.TALENT_CURRENT_SUBSCRIPTION_REQUESTED,
});

export const getTalentSubscriptionBanner = () => ({
  type: ActionTypes.LOAD_BANNER_REQUESTED,
});
export const removeCard = (id) => ({ type: ActionTypes.REMOVE_CARD_REQUESTED, id });
export const loadSubscriptionById = (id) => ({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_REQUESTED, id });
export const getChangePlanOptions = (id, change_mode) => ({ type: ActionTypes.PLAN_CHANGE_OPTIONS_REQUESTED, id, change_mode });
export const getPlanInfo = (id) => ({ type: ActionTypes.PLAN_REQUESTED, id });

export const changeSubscriptionPlan = (new_plan_id, customer_id, subscription_id, payment_id) => ({type:ActionTypes.PLAN_CHANGE_REQUESTED, new_plan_id, customer_id, subscription_id, payment_id});