import { SET_RED_BAR_DISPLAYED } from "../constants/actionTypes";

const initialState = {
    isShowingBar:false
}
const uiReducers = (state = initialState, action) => {
    const { type, isShowingBar } = action;
    if (type === SET_RED_BAR_DISPLAYED) {
        return Object.assign({}, state, {
            isShowingBar: isShowingBar || false
        });
    } else {
        return state;
    }
}
export default uiReducers;