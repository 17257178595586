import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import access from "./access.reducers";
import modalReducers from "./modals.reducers";
import matchesReducer from "./matches.reducers";
import loadingReducer from "./loading.reducers";
import subscriptionReducers from "./subscription.reducers";
import talentProfileReducers from "./talent-profile.reducers";
import messagesReducers from "./messages.reducers";
import adminReducers from "./admin.reducers";

import uiReducers from "./ui.reducers";

/**
 * All reducers
 */
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    access,
    modalReducers,
    matchesReducer,
    loadingReducer,
    subscriptionReducers,
    talentProfileReducers,
    messagesReducers,
    uiReducers,
    adminReducers,
  });
