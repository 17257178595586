module.exports = {
  CV_FORM: "cv_form",
  ONLINE_PRESENCE_FORM: "online_presence_form",
  AREA_OF_EXPERTISE_FORM: "area_of_expertise_form",
  PROFESSIONAL_SUMMARY_FORM: "professional_summary_form",
  SKILLS_FORM: "skills_form",
  LANGUAGES_FORM: "languages_form",
  IDEAL_JOB_FORM: "ideal_job_form",
  PERSONAL_INFORMATION: "personal_information",
  WORK_PREFERENCES: "work_preferences",
  COMMUNICATION_PREFERENCES: "communication_preferences",
  SECURITY: "security",
  NEW_SKILL_FORM: "new_skill_form",
};
