import { put, call, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import { api_put, AUTHORIZATION_ERROR } from "../utils/api";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* saveNewSkill(action) {
  try {
    const response = yield call(api_put, "/api/v1/skills", action.payload);
    if (response.error) throw response;
    else if (!response.skill) {
      yield put({
        type: ActionTypes.SAVE_SKILL_FAILED,
        error: "Please check your permissions",
      });
      return;
    }
    yield put({ type: ActionTypes.SAVE_SKILL_SUCCEEDED });
  } catch (error) {
    let message = error.statusText;
    if (error.status === 401) {
      message = AUTHORIZATION_ERROR.message;
    }
    yield put({ type: ActionTypes.SAVE_SKILL_FAILED, error: message });
  } finally {
    yield delay(3000);
    yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  }
}

export function* watcherSaveSkill() {
  yield takeEvery(ActionTypes.SAVE_SKILL_REQUESTED, saveNewSkill);
}
