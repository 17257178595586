import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  plans: [],
  error: null,
  message: '',
  banner: '',
  plan: null,
  disableSelection: false,
  current_subscription: null,
  payment_method: null,
  stripe_subscription: null,
  change_plan_options: [],
  wisar_plan: null,
  isLoading: false,

}


export default function subscriptionReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_INFO_MESSAGES:
      return Object.assign({}, state, {
        error: null,
        message: '',
        disableSelection: false
      });
    case ActionTypes.PLANS_LOADED:
      return Object.assign({}, state, {
        plans: action.plans,
        message: '',
        error: null,
        plan: null,
        disableSelection: false,
        current_subscription: null,
        payment_method: null
      });
    case ActionTypes.SUBSCRIPTION_PLAN_SELECTED:
      return Object.assign({}, state, {
        plan: action.plan,
        message: "",
        error: null
      });
    case ActionTypes.SUBSCRIPTION_PAYMENT_METHOD_SELECTED:
      return Object.assign({}, state, {
        payment_method: action.method,
        message: "",
        error: null
      });
    case ActionTypes.CREATE_SUBSCRIPTION_SUCCEEDED:
      return Object.assign({}, state, {
        error: null,
        message: action.message,
        stripe_subscription: action.current_subscription,
        isLoading: false,
      });
    case ActionTypes.CREATE_SUBSCRIPTION_REQUESTED:
      return Object.assign({}, state, {
        disableSelection: true,
        message: '',
        error: null,
        isLoading: true
      });
    case ActionTypes.CREATE_SUBSCRIPTION_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        message: '',
        disableSelection: false,
        current_subscription: null,
        isLoading: false
      });

    case ActionTypes.STORE_PAYMENT_METHOD_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        message: '',
        payment_method: null,
        isLoading: false
      });
    case ActionTypes.STORE_PAYMENT_METHOD_REQUESTED:
      return Object.assign({}, state, {
        isLoading: true
      });
    case ActionTypes.STORE_PAYMENT_METHOD_SUCCEEDED: {
      const method = action.payment_method;
      if (method) {
        return Object.assign({}, state, {
          error: null,
          message: '',
          //payment_methods: [...state.payment_methods, method],
          payment_method: action.payment_method,
          isLoading: false
        });
      }
      else return state;
    }
    case ActionTypes.TALENT_CURRENT_SUBSCRIPTION_LOADED:
      return Object.assign({}, state, {
        message: "",
        error: null,
        current_subscription: action.current_subscription
      });
    case ActionTypes.TALENT_CURRENT_SUBSCRIPTION_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        current_subscription: null
      });
    case ActionTypes.LOAD_BANNER_SUCCEEDED:
      return Object.assign({}, state, {
        message: "",
        error: null,
        banner: action.banner
      });
    case ActionTypes.LOAD_BANNER_FAILED:
      return Object.assign({}, state, {
        message: "",
        error: action.error,
        banner: ""
      });
    case ActionTypes.PLAN_CHANGE_REQUESTED: {
      return Object.assign({}, state, {
        isLoading: true,
      })
    }
    case ActionTypes.PLAN_CHANGE_SUCCEEDED:
    case ActionTypes.LOAD_SUBSCRIPTION_ID_SUCCEEDED: {
      return Object.assign({}, state, {
        stripe_subscription: action.subscription,
        isLoading: false
      })
    }
    case ActionTypes.PLAN_CHANGE_FAILED:
    case ActionTypes.LOAD_SUBSCRIPTION_ID_FAILED: {
      return Object.assign({}, state, {
        stripe_subscription: null,
        message: "",
        error: action.error,
        isLoading: false
      })
    }
    case ActionTypes.SUBSCRIPTION_CANCELATION_SUCCEEDED:
    case ActionTypes.SUBSCRIPTION_COUPON_SUCCEEDED: {
      return Object.assign({}, state, {
        stripe_subscription: action.subscription,
        message: "",
        error: action.error,
      })
    }
    case ActionTypes.PLAN_CHANGE_OPTIONS_SUCCEEDED: {
      return Object.assign({}, state, {
        change_plan_options: action.plans,
      })
    }
    case ActionTypes.PLAN_SUCCEEDED: {
      return Object.assign({}, state, {
        wisar_plan: action.plan,
      })
    }

    default:
      return state;
  }
}
