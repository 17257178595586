import React from "react";
import Loadable from "react-loadable";
import loading_icon from "./assets/img/loading.gif";

const Loading = () => (
  <div id="mainContent mt-3" className="position-relative w-100 d-block">
    <div className="w-100 d-block">
      <div className="signUpCard align-middle text-center">
        <img
          className="loadingIcon"
          src={loading_icon}
          alt="loading icon"
          width="10%"
        />
      </div>
    </div>
  </div>
);

export const AsyncLoginView = Loadable({
  loader: () => import("./components/user-access/login"),
  loading: () => <Loading />,
});
export const AsyncSignupView = Loadable({
  loader: () => import("./components/user-access/signup"),
  loading: () => <Loading />,
});
export const AsyncForgotPaswordView = Loadable({
  loader: () => import("./components/user-access/forgot-password"),
  loading: () => <Loading />,
});
export const AsyncChangePasswordView = Loadable({
  loader: () => import("./components/user-access/change-password"),
  loading: () => <Loading />,
});
export const AsyncResetPasswordView = Loadable({
  loader: () => import("./components/user-access/reset-password"),
  loading: () => <Loading />,
});
export const AsyncActivateAccountView = Loadable({
  loader: () => import("./components/user-access/activate-account"),
  loading: () => <Loading />,
});

export const AsyncTalentAccountView = Loadable({
  loader: () => import("./components/talent-profile/talent-profile"),
  loading: () => <Loading />,
});
export const AsyncMatchesView = Loadable({
  loader: () => import("./components/matches/matches"),
  loading: () => <Loading />,
});
export const AsyncSubscriptionView = Loadable({
  loader: () => import("./components/subscription/subscription"),
  loading: () => <Loading />,
});
export const AsyncNoCardSubscriptionView = Loadable({
  loader: () =>
    import("./components/subscription/nocard-subscription/nocard-subscription"),
  loading: () => <Loading />,
});
export const AsyncOnboardingView = Loadable({
  loader: () =>
    import("./components/talent-profile/onboarding-profile/onboarding-profile"),
  loading: () => <Loading />,
});
export const AsyncMySubscriptionView = Loadable({
  loader: () => import("./components/my-subscription/my-subscription"),
  loading: () => <Loading />,
});
export const AsyncSettingsView = Loadable({
  loader: () => import("./components/settings/settings"),
  loading: () => <Loading />,
});
export const AsyncAdminView = Loadable({
  loader: () => import("./components/admin/admin"),
  loading: () => <Loading />,
});
