import * as ActionTypes from "../constants/actionTypes";
import { SUCCEEDED_GENERAL_MESSAGE } from "../constants/messages";

const initialState = {
  result_message: "",
  result_error: null,
  isLoading: false,
  new_skill_form: {
    skill_id: null,
  },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_SKILL_SUCCEEDED:
      return Object.assign({}, state, {
        result_message: SUCCEEDED_GENERAL_MESSAGE,
        result_error: null,
        isLoading: false,
      });
    case ActionTypes.SAVE_SKILL_FAILED:
      return Object.assign({}, state, {
        result_message: "",
        result_error: action.error,
        isLoading: false,
      });
    case ActionTypes.CLEAR_INFO_MESSAGES:
      return Object.assign({}, state, {
        result_message: "",
        result_error: null,
        isLoading: false,
      });
    default:
      return state;
  }
};
export default adminReducer;
