import React, { Component } from 'react';
import './footer.css';
class Footer extends Component {
  render() {
    return (
<footer>
      <div className="container border-top">
   {/*      <div className="row logo">
        <img src={logo} alt="Wisar" className="img-fluid"/>
        </div> */}
        <div className="row">
          <div className="col-md">
            <h2>MENU</h2>
            <ul>
              <li><a href="https://wisar.pro">HOME</a></li>
              <li><a href="https://wisar.pro/about">ABOUT US</a></li>
              <li><a href="https://wisar.pro/blog">BLOG</a></li>
              <li><a href="https://wisar.pro/contact">CONTACT</a></li>
            </ul>
          </div>
          <div className="col-md">
            <h2>LEGAL</h2>
            <ul>
              <li><a href="https://wisar.pro/terms/">TERMS &amp; CONDITIONS</a></li>
              <li><a href="https://wisar.pro/privacy/">PRIVACY POLICY</a></li>
              <li><a href="https://wisar.pro/cookies/">COOKIE POLICY</a></li>
            </ul>
          </div>
          <div className="col-md">
            <h2>FOLLOW US</h2>
            <ul className="social-icons">
              <li><a href="http://www.linkedin.com/company/wisartr" target="_blank"><i className="fab fa-linkedin-in"></i> <span className="sr-only">Linkedin</span></a></li>
              <li><a href="https://www.facebook.com/wisarTR/" target="_blank"><i className="fab fa-facebook-f"></i> <span className="sr-only">Facebook</span></a></li>
              <li><a href="https://twitter.com/WisarTR" target="_blank"><i className="fab fa-twitter"></i> <span className="sr-only">Twitter</span></a></li>
              <li><a href=" https://www.instagram.com/wisar.pro" target="_blank"><i className="fab fa-instagram"></i> <span className="sr-only">Instagram</span></a></li>
            </ul>
          </div>
        </div>

        <div className="row">
          <p className="bottom">Made with <strong>♥</strong> in Barcelona, Spain.</p>
        </div>
      </div>
    </footer>
    );
  }
}
 
export default Footer;