import { SET_LOADING } from "../constants/actionTypes";

const initialState = {
    scope:"",
    loading:false,
    text:""
}
const loadingReducer = (state = initialState, action) => {
    const { type, payload } = action;
    if (type === SET_LOADING) {
        return Object.assign({}, state, {
            // sets the loading boolean at this scope
          //  [`${payload.scope}Loading`]: payload.loading,
            loading: payload.loading || false,
            text:payload.text || ""
        });
    } else {
        return state;
    }
}
export default loadingReducer;