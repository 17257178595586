import * as ActionTypes from "../constants/actionTypes";
import Cookies from "js-cookie";
import { COOKIE_USER } from "../config";
import * as forms from "../constants/form-names";

/**
 * Default values.
 * Forms conect to each individual section
 */
const initialState = {
  cv_form: {
    cv_extract_english: "",
  },
  online_presence_form: {
    linkedin_profile: "",
    urls: [],
    instagram: "",
    github: "",
    twitter: "",
  },
  area_of_expertise_form: {
    main_category: "",
    areas_of_expertise: [],
    subcategories: [],
  },
  professional_summary_form: {
    professional_summary: "",
  },
  skills_form: {
    skill_array: [],
  },
  languages_form: {
    native_languages: [],
    fluent_languages: [],
  },
  ideal_job_form: {
    ideal_job: "",
  },
  jobs: null,
  count: 0,
  all_skills: [],
  talent: null,
  stripe_customer: null,
  stripe_subscription: null,
  stripe_payment_methods: null,
  cancelation_message: "",
  cancelation_error: null,
  subscription_area_message: "",
  subscription_area_error: null,
  security_area_message: "",
  security_area_error: null,
  openCustomerForm: false,
  percentage_completion: undefined,
  current_status: "",
  personal_information: {
    fullname: "",
    email: "",
    address: "",
    current_city: "",
    current_country: "",
    current_state: "",
    phone: "",
    sex: "NA",
  },
  professional_profile: {
    main_category: "",
    areas_of_expertise: [],
    subcategories: [],
    professional_summary: "",
    urls: [],
    linkedin_profile: "",
    expertise_level: "Expert",
    native_languages: [],
    fluent_languages: [],
    ideal_job: "",
    other_projects: "",
    cv_extract_english: "",
    skills: "",
    skill_array: [],
  },
  work_preferences: {
    catalant_excluded: false,
    fiverr_excluded: false,
    freelancer_excluded: false,
    peopleperhour_excluded: false,
    talentexchange_excluded: false,
    upwork_excluded: false,
    availabilitytowork: "Less than 30 hrs/week",
    current_availability: "Available",
    expected_income: "",
    currency: "USD",
  },
  communication_preferences: {
    newsletter_period: "Daily",
    newsletter_active: true,
  },
  matches_preview_quantity: 0,
};

/**
 * Reducer logic for each Action
 * @param {*} state
 * @param {*} action
 */
export default function talentProfileReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_PERCENTAGE_COMPLETION: {
      return Object.assign({}, state, {
        percentage_completion: action.percentage_completion,
      });
    }
    case ActionTypes.SET_CURRENT_STATUS: {
      return Object.assign({}, state, {
        current_status: action.current_status,
      });
    }
    case ActionTypes.SAVE_PROFILE_SUCCEEDED:
      return Object.assign({}, state, {
        talent: action.talent,
        percentage_completion: calculatePercentageOfCompletion(action.talent),
      });
    case ActionTypes.LOAD_PROFILE_SUCCEEDED: {
      return Object.assign({}, state, {
        talent: action.talent,
        stripe_customer: action.customer,
        personal_information: getPersonalInfo(action.talent),
        professional_profile: getProfessionalProfile(action.talent),
        work_preferences: getWorkPreference(action.talent),
        communication_preferences: getCommunicationPreferences(action.talent),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
        cv_form: { cv_extract_english: action.talent.cv_extract_english },
        online_presence_form: {
          linkedin_profile: action.talent.linkedin_profile,
          urls: action.talent.urls,
          instagram: action.talent.instagram,
          github: action.talent.github,
          twitter: action.talent.twitter,
        },
        area_of_expertise_form: {
          main_category:
            action.talent && action.talent.main_category
              ? action.talent.main_category
              : "",
          areas_of_expertise:
            action.talent && action.talent.areas_of_expertise
              ? action.talent.areas_of_expertise
              : [],
          subcategories:
            action.talent && action.talent.subcategories
              ? action.talent.subcategories
              : [],
        },
        professional_summary_form: {
          professional_summary:
            action.talent && action.talent.professional_summary
              ? cleanTags(action.talent.professional_summary)
              : "",
        },
        skills_form: {
          skills:
            action.talent && action.talent.skills
              ? cleanTags(action.talent.skills)
              : "",
          skill_array:
            action.talent && action.talent.skill_array
              ? action.talent.skill_array
              : [],
        },
        languages_form: {
          native_languages:
            action.talent && action.talent.native_languages
              ? action.talent.native_languages
              : [],
          fluent_languages:
            action.talent && action.talent.fluent_languages
              ? action.talent.fluent_languages
              : [],
        },
        ideal_job_form: {
          ideal_job:
            action.talent && action.talent.ideal_job
              ? cleanTags(action.talent.ideal_job)
              : "",
        },
      });
    }
    case ActionTypes.SAVE_PARTIAL_TALENT_SUCCEEDED: {
      return Object.assign({}, state, {
        [`${action.form}`]: getUpdatedInfoIntoForm(action.talent, action.form),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
        talent: action.talent,
      });
    }
    case ActionTypes.SAVE_PERSONAL_INFO_SUCCEEDED:
      return Object.assign({}, state, {
        personal_information: getPersonalInfo(action.talent),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
      });
    case ActionTypes.SAVE_PROFFESIONAL_PROFILE_SUCCEEDED:
      return Object.assign({}, state, {
        professional_profile: getProfessionalProfile(action.talent),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
      });
    case ActionTypes.SAVE_WORK_PREFERENCE_SUCCEEDED:
      return Object.assign({}, state, {
        work_preferences: getWorkPreference(action.talent),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
      });
    case ActionTypes.SAVE_COMMUNICATION_PREFERENCE_SUCCEEDED:
      return Object.assign({}, state, {
        communication_preferences: getCommunicationPreferences(action.talent),
        percentage_completion: calculatePercentageOfCompletion(action.talent),
      });

    case ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED:
      return Object.assign({}, state, {
        stripe_customer: action.customer,
      });
    case ActionTypes.LOAD_STRIPE_CUSTOMER_FAILED:
      return Object.assign({}, state, {
        stripe_customer: null,
      });
    case ActionTypes.SUBSCRIPTION_COUPON_REQUESTED:
    case ActionTypes.SUBSCRIPTION_CANCELATION_REQUESTED:
      return Object.assign({}, state, {
        cancelation_message: "",
        cancelation_error: null,
      });
    case ActionTypes.SUBSCRIPTION_COUPON_FAILED:
    case ActionTypes.SUBSCRIPTION_CANCELATION_FAILED:
      return Object.assign({}, state, {
        cancelation_error: action.error,
        cancelation_message: "",
      });
    case ActionTypes.SUBSCRIPTION_COUPON_SUCCEEDED:
    case ActionTypes.SUBSCRIPTION_CANCELATION_SUCCEEDED:
      return Object.assign({}, state, {
        cancelation_error: null,
        cancelation_message: action.message,
      });

    case ActionTypes.UPDATE_STRIPE_CUSTOMER_SUCCEEDED:
      return Object.assign({}, state, {
        subscription_area_error: null,
        subscription_area_message: action.message,
        stripe_customer: action.customer,
        openCustomerForm: false,
      });
    case ActionTypes.DELETE_ACCOUNT_REQUESTED:
      return Object.assign({}, state, {
        security_area_message: "Processing ...",
        security_area_error: null,
      });
    case ActionTypes.DELETE_ACCOUNT_SUCCEEDED:
      return Object.assign({}, state, {
        security_area_message: action.message || "Finished",
        security_area_error: null,
      });
    case ActionTypes.DELETE_ACCOUNT_FAILED:
      return Object.assign({}, state, {
        security_area_message: "",
        security_area_error: action.error,
      });
    case ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED:
      return Object.assign({}, state, {
        subscription_area_error: null,
        subscription_area_message: action.message,
        stripe_customer: action.customer,
      });
    case ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_SUCCEEDED:
      return Object.assign({}, state, {
        subscription_area_error: null,
        subscription_area_message: action.message,
        stripe_customer: action.customer,
      });
    case ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_FAILED:
    case ActionTypes.UPDATE_STRIPE_CUSTOMER_FAILED:
    case ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_FAILED:
      return Object.assign({}, state, {
        subscription_area_error: action.error,
        subscription_area_message: "",
      });
    case ActionTypes.CLEAR_INFO_MESSAGES:
      return Object.assign({}, state, {
        subscription_area_error: null,
        subscription_area_message: "",
        cancelation_message: "",
        cancelation_error: null,
        security_area_message: "",
        security_area_error: null,
      });
    case ActionTypes.COPY_TALENT_ADDRESS_INTO_CUSTOMER_ADDRESS:
      var personal_information = state.personal_information;
      var email =
        personal_information && personal_information.email
          ? personal_information.email
          : "";
      var phone =
        personal_information && personal_information.phone
          ? personal_information.phone
          : "";
      var name =
        personal_information && personal_information.fullname
          ? personal_information.fullname
          : "";
      var customer = { ...state.stripe_customer };
      customer.email = email;
      customer.phone = phone;
      customer.name = name;
      customer.address = { ...state.stripe_customer.address };
      customer.address.city =
        personal_information && personal_information.current_city
          ? personal_information.current_city
          : "";
      customer.address.line1 =
        personal_information && personal_information.address
          ? personal_information.address
          : "";
      customer.address.country =
        personal_information && personal_information.current_country
          ? personal_information.current_country
          : "";
      customer.address.state =
        personal_information && personal_information.current_state
          ? personal_information.current_state
          : "";
      return Object.assign({}, state, {
        openCustomerForm: true,
        stripe_customer: customer,
      });
    case ActionTypes.OPEN_CUSTOMER_FORM:
      return Object.assign({}, state, {
        openCustomerForm: true,
      });
    case ActionTypes.CLOSE_CUSTOMER_FORM:
      return Object.assign({}, state, {
        openCustomerForm: false,
      });
    case ActionTypes.CUSTOMER_PAYMENT_METHODS_LOADED:
      return Object.assign({}, state, {
        stripe_payment_methods: action.payment_methods,
        subscription_area_error: null,
        subscription_area_message: "",
      });
    case ActionTypes.CUSTOMER_PAYMENT_METHODS_FAILED: {
      return Object.assign({}, state, {
        stripe_payment_methods: null,
        subscription_area_error: action.error,
        subscription_area_message: "",
      });
    }
    case ActionTypes.CATEGORY_JOBS_PREVIEW_SUCCEEDED: {
      return Object.assign({}, state, {
        jobs: action.jobs,
        count: action.count,
      });
    }
    case ActionTypes.PREVIEW_MATCHES_SUCCEEDED: {
      return Object.assign({}, state, {
        matches_preview_quantity: action.preview_quantity,
        ran_matches_preview_quantity: true,
      });
    }
    case ActionTypes.PREVIEW_MATCHES_FAILED: {
      return Object.assign({}, state, {
        matches_preview_quantity: 0,
        ran_matches_preview_quantity: true,
      });
    }
    case ActionTypes.ALL_SKILLS_FAILED:
      return Object.assign({}, state, {
        all_skills: [],
        isLoading: false,
      });
    case ActionTypes.ALL_SKILLS_LOADED:
      return Object.assign({}, state, {
        all_skills: action.all_skills,
        isLoading: false,
      });
    default:
      return state;
  }
}
/**
 * Remove HTML tags comming from previusly created Talents (via Podio)
 * @param {*} talent
 */
const cleanTags = (text) => {
  if (text && text.length > 0) {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }
  return text;
};
/**
 * Extract Cokkie to populate "Email" field of Perfonal information form
 * @param {*} talent
 */
const getEmailFromCookie = () => {
  const loggedUserObj = Cookies.getJSON(COOKIE_USER);
  if (loggedUserObj && loggedUserObj.email) {
    return loggedUserObj.email;
  }
  return "";
};
/**
 * Extract Cokkie to populate "Fullname" field of Perfonal information form
 * @param {*} talent
 */
const getFullnameFromCookie = () => {
  const loggedUserObj = Cookies.getJSON(COOKIE_USER);
  if (loggedUserObj && loggedUserObj.fullname) {
    return loggedUserObj.fullname;
  }
  return "";
};
/**
 * Extract properties from Talent object to populate info on the proper form
 * @param {*} talent
 */
function getUpdatedInfoIntoForm(talent, form) {
  let info = {};
  switch (form) {
    case forms.CV_FORM:
      info.cv_extract_english =
        talent && talent.cv_extract_english
          ? cleanTags(talent.cv_extract_english)
          : "";
      break;
    case forms.ONLINE_PRESENCE_FORM: {
      info.linkedin_profile =
        talent && talent.linkedin_profile ? talent.linkedin_profile : "";
      info.urls = talent && talent.urls ? talent.urls : [];
      info.instagram = talent && talent.instagram ? talent.instagram : "";
      info.github = talent && talent.github ? talent.github : "";
      info.twitter = talent && talent.twitter ? talent.twitter : "";
      break;
    }
    case forms.AREA_OF_EXPERTISE_FORM:
      info.main_category =
        talent && talent.main_category ? talent.main_category : "";
      info.areas_of_expertise =
        talent && talent.areas_of_expertise ? talent.areas_of_expertise : [];
      info.subcategories =
        talent && talent.subcategories ? talent.subcategories : [];
      break;
    case forms.PROFESSIONAL_SUMMARY_FORM:
      info.professional_summary =
        talent && talent.professional_summary
          ? cleanTags(talent.professional_summary)
          : "";
      break;
    case forms.SKILLS_FORM:
      info.skills = talent && talent.skills ? cleanTags(talent.skills) : "";
      info.skill_array = talent && talent.skill_array ? talent.skill_array : [];
      break;
    case forms.LANGUAGES_FORM:
      info.native_languages =
        talent && talent.native_languages ? talent.native_languages : [];
      info.fluent_languages =
        talent && talent.fluent_languages ? talent.fluent_languages : [];
      break;
    case forms.IDEAL_JOB_FORM:
      info.ideal_job =
        talent && talent.ideal_job ? cleanTags(talent.ideal_job) : "";
      break;
    default:
      break;
  }
  return info;
}

/**
 * Extract properties from Talent object to populate form "Personal Information"
 * @param {*} talent
 */
function getPersonalInfo(talent) {
  const personal_information = {
    fullname:
      talent && talent.fullname ? talent.fullname : getFullnameFromCookie(),
    email: talent && talent.email ? talent.email : getEmailFromCookie(),
    address: talent && talent.address ? talent.address : "",
    current_city: talent && talent.current_city ? talent.current_city : "",
    current_country:
      talent && talent.current_country ? talent.current_country : "",
    current_state: talent && talent.current_state ? talent.current_state : "",
    phone: talent && talent.phone ? talent.phone : "",
    sex: talent && talent.sex ? talent.sex : "NA",
    /*  personal_identification_number : talent && talent.personal_identification_number ? talent.personal_identification_number: "",
      reverseVat: talent.reverseVat*/
  };
  return personal_information;
}
/**
 * Extract properties from Talent object to populate form "Professional Profile"
 * @param {*} talent
 */
function getProfessionalProfile(talent) {
  try {
    const information = {
      main_category: talent && talent.main_category ? talent.main_category : "",
      areas_of_expertise:
        talent && talent.areas_of_expertise ? talent.areas_of_expertise : [],
      subcategories: talent && talent.subcategories ? talent.subcategories : [],
      professional_summary:
        talent && talent.professional_summary
          ? cleanTags(talent.professional_summary)
          : "",
      urls: talent && talent.urls ? talent.urls : [],
      linkedin_profile:
        talent && talent.linkedin_profile ? talent.linkedin_profile : "",
      expertise_level:
        talent && talent.expertise_level ? talent.expertise_level : "Expert",
      native_languages:
        talent && talent.native_languages ? talent.native_languages : [],
      fluent_languages:
        talent && talent.fluent_languages ? talent.fluent_languages : [],
      ideal_job: talent && talent.ideal_job ? cleanTags(talent.ideal_job) : "",
      other_projects:
        talent && talent.other_projects ? cleanTags(talent.other_projects) : "",
      skills: talent && talent.skills ? cleanTags(talent.skills) : "",
      cv_extract_english:
        talent && talent.cv_extract_english
          ? cleanTags(talent.cv_extract_english)
          : "",
    };
    return information;
  } catch (error) {
    console.log(error);
  }
}
/**
 * Extract properties from Talent object to populate form "Work preference"
 * @param {*} talent
 */
function getWorkPreference(talent) {
  const information = {
    catalant_excluded:
      talent && talent.catalant_excluded ? talent.catalant_excluded : false,
    fiverr_excluded:
      talent && talent.fiverr_excluded ? talent.fiverr_excluded : false,
    freelancer_excluded:
      talent && talent.freelancer_excluded ? talent.freelancer_excluded : false,
    peopleperhour_excluded:
      talent && talent.peopleperhour_excluded
        ? talent.peopleperhour_excluded
        : false,
    talentexchange_excluded:
      talent && talent.talentexchange_excluded
        ? talent.talentexchange_excluded
        : false,
    upwork_excluded:
      talent && talent.upwork_excluded ? talent.upwork_excluded : false,
    availabilitytowork:
      talent && talent.availabilitytowork
        ? talent.availabilitytowork
        : "Less than 30 hrs/week",
    current_availability:
      talent && talent.current_availability
        ? talent.current_availability
        : "Available",
    expected_income:
      talent && talent.expected_income ? talent.expected_income : "",
    currency: talent && talent.currency ? talent.currency : "USD",
  };
  return information;
}
/**
 * Extract properties from Talent object to populate form "Communication preference"
 * @param {*} talent
 */
function getCommunicationPreferences(talent) {
  const i = {
    newsletter_period:
      talent && talent.newsletter_period ? talent.newsletter_period : "Daily",
    newsletter_active:
      talent && talent.newsletter_active ? talent.newsletter_active : false,
  };
  return i;
}
/**
 * Iterate over talent's properties
 * if talent has a field in the lists (lists of fields to consider),
 * and that field is not blank or empty adds 1 to "n"
 * to n, then it takes the following formula to find the percentage of completion:
 * n = populated fields
 * % = n * 100 / 20
 * @param {} talent
 */
function calculatePercentageOfCompletion(talent) {
  const percentage_completion =
    talent &&
    talent.hasOwnProperty("percentage_completion") &&
    talent.percentage_completion !== "undefined"
      ? talent.percentage_completion
      : 0;
  return percentage_completion;
}
