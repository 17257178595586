import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  modalProps: {
    open: false,
    title: '',
    message: '',
    closeModal: null,
    link:''
  },
  modalType: 'alert'
}


export default function modalReducers(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL_SUCCEEDED:
      return { login: action.newState };
    case ActionTypes.SHOW_MODAL:
      return Object.assign({}, state, {
        modalProps: action.payload.modalProps,
        modalType: action.payload.modalType,
        type: action.payload.type
      });
    case ActionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
