import { takeEvery, call, put, select } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import * as messages from "../constants/messages";
import Cookies from 'js-cookie';
import { api_get, api_post, api_put } from '../utils/api';
import { push } from 'connected-react-router'
import { WISAR_SUBSCRIPTION_KEY, COOKIE_USER, WISAR_SUBSCRIPTION_STATUS_KEY, WISAR_TOKEN_KEY, ELIGIBLE_SUBSCRIPTION_URL_KEY, ELEGIBLE_URL_SUBSCRIPTION, ELEGIBLE_URL_TRIAL } from '../config';
import jwt_decode from "jwt-decode";

export const getUser = (state) => {
  return state.access.current_user ? state.access.current_user : null
};
export const getTalent = (state) => {
  return state.talentProfileReducers.talent ? state.talentProfileReducers.talent : null
};
const delay = (ms) => new Promise(res => setTimeout(res, ms))

/**
 * Load Subscription plans
 */
export function* watcherRequestSubscriptionPlansSaga() {
  yield takeEvery(ActionTypes.PLANS_REQUESTED, workerSaga);
}

function* workerSaga() {
  try {

    let user = Cookies.getJSON(COOKIE_USER);
    if (!user || !user._id || !user.talent_id) {
      yield put({ type: ActionTypes.FORCE_LOGIN });
      return;
    }
    let category = user && user.category ? user.category : "regular";
    const response = yield call(
      api_get,
      `/api/v1/plans?category=${category}`
    );
    yield put({ type: ActionTypes.PLANS_LOADED, plans: response.plans });
  } catch (e) {
    yield put({ type: ActionTypes.API_ERRORED, payload: e });
  }
}

/**
 *  handles creating customer and a subscription plan on stripe
 */
export function* watcherSubscribeWithPaymentMethodSaga() {
  yield takeEvery(ActionTypes.CREATE_SUBSCRIPTION_REQUESTED, subscribeWithPaymentMethod);
}
/**
 * Creates a subscription. If stripe_payment_method equals to -- then calls the nocardsubscription endpoint
 * @param {*} action 
 */
function* subscribeWithPaymentMethod(action) {
  const { plan_id, stripe_payment_method } = action;

  //let user = yield select(getUser);
  let user = Cookies.getJSON(COOKIE_USER);
  let user_id = user ? user._id : null;
  if (!user_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
    return;
  }

  try {
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: !stripe_payment_method ? "" : messages.SUBSCRIPTION_PAYMENT_IN_PROGRESS } });

    let response = null;
    let body = { plan_id, user_id, stripe_payment_method }
    if (!stripe_payment_method && localStorage.getItem(ELIGIBLE_SUBSCRIPTION_URL_KEY) === ELEGIBLE_URL_TRIAL) {
      body.want_trial = true;
    } else {
      body.want_trial = false;
    }
    response = yield call(
      api_post,
      `/api/v1/subscriptions/`,
      body,
    );
    if (response.error) {
      yield put({ type: ActionTypes.CREATE_SUBSCRIPTION_FAILED, error: response.error });
    }
    else {
      if (response.subscription && response.subscription.id) {
        localStorage.setItem(WISAR_SUBSCRIPTION_KEY, response.subscription.id)
      }

      if (response.subscription) {
        yield put({ type: ActionTypes.SET_CURRENT_STATUS, current_status: response.subscription.status })
        localStorage.setItem(WISAR_SUBSCRIPTION_STATUS_KEY, response.subscription.status);
      }
      yield put({ type: ActionTypes.CREATE_SUBSCRIPTION_SUCCEEDED, message: response.message, current_subscription: response.subscription });
      if (response.token) {
        var decoded = jwt_decode(response.token);
        yield put({ type: ActionTypes.SET_JWT_OBJECT, decoded: decoded });
        localStorage.setItem(WISAR_TOKEN_KEY, response.token)
      }
      localStorage.setItem(ELIGIBLE_SUBSCRIPTION_URL_KEY, ELEGIBLE_URL_SUBSCRIPTION);
      if (!response.percentage_completion || response.percentage_completion < 80) {
        yield put(push('/onboard'))
      }
      else {
        //Your payment is confirmed. You'll be redirected to your matches page now
        if (stripe_payment_method) {
          yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: messages.SUBSCRIPTION_RESULT } });
          yield delay(2000);
          yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
        }
        yield put(push('/matches'))
      }
      yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
    }
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_SUBSCRIPTION_FAILED, error });
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });

  }
}

/**
 *  handles creating customer and a subscription plan on stripe
 */
export function* watcherStorePaymentMethodSaga() {
  yield takeEvery(ActionTypes.STORE_PAYMENT_METHOD_REQUESTED, storePaymentMethod);
}
/**
*
* @param {*} action 
*/
function* storePaymentMethod(action) {
  const { stripe_payment_method } = action;

  //  let user = yield select(getUser);
  let user = Cookies.getJSON(COOKIE_USER);
  let user_id = user ? user._id : null;
  if (!user_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
    return;
  }
  else {
    try {
      yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: messages.STORING_PAYMENT_IN_PROGRESS } });

      const response = yield call(
        api_post,
        `/api/v1/subscriptions/store-payment`,
        { stripe_payment_method, user_id },
      );
      yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
      if (response.error) {
        yield put({ type: ActionTypes.STORE_PAYMENT_METHOD_FAILED, error: response.error });
      }
      else {
        if (response.customer && response.customer.id) {
          yield put({ type: ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED, customer: response.customer });
        }
        yield put({ type: ActionTypes.STORE_PAYMENT_METHOD_SUCCEEDED, message: response.message, payment_method: response.payment_method });
        yield put({ type: ActionTypes.CUSTOMER_PAYMENT_METHODS_LOADED, payment_methods: response.payment_methods });
        yield put({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_REQUESTED });
        //yield delay(2000);
        yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
      }

    } catch (error) {
      yield put({ type: ActionTypes.STORE_PAYMENT_METHOD_FAILED, error });
    }
  }

}


export function* watcherGetTalentCurrentSubscriptionSaga() {
  yield takeEvery(ActionTypes.TALENT_CURRENT_SUBSCRIPTION_REQUESTED, getTalentCurrentSunscription);
}
/**
*
* @param {*} action 
*/
function* getTalentCurrentSunscription() {

  ///let user = yield select(getUser);
  let user = Cookies.getJSON(COOKIE_USER);
  let talent_id = user ? user.talent_id : '';
  if (!talent_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
    return;
  }

  try {
    const response = yield call(
      api_get,
      `/api/v1/subscriptions/talents/${talent_id}`,
    );
    if (response.error) {
      yield put({ type: ActionTypes.TALENT_CURRENT_SUBSCRIPTION_FAILED, error: response.error });
    }
    else {
      yield put({ type: ActionTypes.TALENT_CURRENT_SUBSCRIPTION_LOADED, current_subscription: response.subscription });
    }
  } catch (error) {
    yield put({ type: ActionTypes.TALENT_CURRENT_SUBSCRIPTION_FAILED, error });
  }
}


export function* watcherGetTalentSubscriptionBannerSaga() {
  yield takeEvery(ActionTypes.LOAD_BANNER_REQUESTED, getTalentSubscriptionBanner);
}
/**
*
* @param {*} action 
*/
function* getTalentSubscriptionBanner() {

  //let user = yield select(getUser);
  //let talent_id = user ? user.talent_id : '';
  let u = Cookies.getJSON(COOKIE_USER);
  let talent_id = u ? u.talent_id : '';
  if (!talent_id) {
    return;
  }
  try {
    const response = yield call(
      api_get,
      `/api/v1/stripe/subscriptions/banner-info/${talent_id}`,
    );
    if (response.error) {
      yield put({ type: ActionTypes.LOAD_BANNER_FAILED, error: response.error });
    }
    else {
      yield put({ type: ActionTypes.LOAD_BANNER_SUCCEEDED, banner: response.banner });
    }
  } catch (error) {
    yield put({ type: ActionTypes.LOAD_BANNER_FAILED, error });
  }
}


export function* watcherRemoveCreditCardSaga() {
  yield takeEvery(ActionTypes.REMOVE_CARD_REQUESTED, removeCreditCard);
}
/**
*
* @param {*} action 
*/
function* removeCreditCard(action) {
  let talent = yield select(getTalent);

  let u = Cookies.getJSON(COOKIE_USER);
  let talent_id = u ? u.talent_id : '';

  if (!talent || !talent.stripe_customer_id) {
    return;
  }
  const id = action.id;
  const stripe_customer_id = talent.stripe_customer_id
  yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true } });

  try {
    const response = yield call(
      api_put,
      `/api/v1/stripe/customer/payment_methods`,
      { stripe_customer_id, id }
    );
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
    if (response.customer) {
      yield put({ type: ActionTypes.LOAD_STRIPE_CUSTOMER_SUCCEEDED, customer: response.customer });
    }
    if (response.payment_methods) {
      yield put({ type: ActionTypes.CUSTOMER_PAYMENT_METHODS_LOADED, payment_methods: response.payment_methods });
    }
    yield put({ type: ActionTypes.SET_INFO_MESSAGE, message: messages.SUCCEEDED_GENERAL_MESSAGE });
  } catch (error) {
    yield put({ type: ActionTypes.SET_ERROR_MESSAGE, message: JSON.stringify(error.message) });
  }
}

//success_url=
//cancel_url="users.wisar.co/subscription"



export function* watcherLoadSubscriptionByIdSaga() {
  yield takeEvery(ActionTypes.LOAD_SUBSCRIPTION_ID_REQUESTED, loadSubscriptionById);
}
/**
*
* @param {*} action 
*/
function* loadSubscriptionById(action) {
  const id = localStorage.getItem(WISAR_SUBSCRIPTION_KEY);
  if (!id) {
    return;
  }
  try {
    const response = yield call(
      api_get,
      `/api/v1/stripe/subscription_expanded/${id}`
    );
    if (response.subscription) {
      if (response.subscription.id) {
        localStorage.setItem(WISAR_SUBSCRIPTION_KEY, response.subscription.id)
      }
      yield put({ type: ActionTypes.SET_CURRENT_STATUS, current_status: response.subscription.status })
      localStorage.setItem(WISAR_SUBSCRIPTION_STATUS_KEY, response.subscription.status);
      yield put({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_SUCCEEDED, subscription: response.subscription });
    }
  } catch (error) {
    yield put({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_FAILED, error });
  }
}


/**
 *  handles creating customer and a subscription plan on stripe
 */
export function* watcherGetPlanChangeOptionsSaga() {
  yield takeEvery(ActionTypes.PLAN_CHANGE_OPTIONS_REQUESTED, getPlanChangeOptions);
}
/**
 * Retrieves downgrade / upgrade options
 * @param {*} action 
 */
function* getPlanChangeOptions(action) {
  const { id, change_mode } = action;
  try {
    let response = null;
    const url = change_mode === 'downgrade' ? `/api/v1/plans/downgrade-plans/${id}` : `/api/v1/plans/upgrade-plans/${id}`
    response = yield call(
      api_get,
      url
    );
    if (response.error) {
      yield put({ type: ActionTypes.PLAN_CHANGE_OPTIONS_FAILED, error: response.error });
    }
    else {
      yield put({ type: ActionTypes.PLAN_CHANGE_OPTIONS_SUCCEEDED, message: response.message, plans: response.plans });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PLAN_CHANGE_OPTIONS_FAILED, error });

  }
}

/**
 *  Retrieves wisar plan info
 */
export function* watcherGetPlanSaga() {
  yield takeEvery(ActionTypes.PLAN_REQUESTED, getPlanInfo);
}
function* getPlanInfo(action) {
  const { id } = action;
  try {
    let response = null;
    response = yield call(
      api_get,
      `/api/v1/plans/${id}`
    );

    if (response.error) {
      yield put({ type: ActionTypes.PLAN_FAILED, error: response.error });
    }
    else {
      yield put({ type: ActionTypes.PLAN_SUCCEEDED, message: response.message, plan: response.plan });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PLAN_CHANGE_OPTIONS_FAILED, error });

  }
}
/**
 *  Change subscription plan
 */
export function* watcherChangePlanSaga() {
  yield takeEvery(ActionTypes.PLAN_CHANGE_REQUESTED, changePlan);
}
function* changePlan(action) {
  const { new_plan_id, customer_id, subscription_id, payment_id } = action;
  let user = Cookies.getJSON(COOKIE_USER);
  let user_id = user ? user._id : null;
  if (!user_id) {
    yield put({ type: ActionTypes.FORCE_LOGIN });
    return;
  }
  try {

    let isDowngrade = !payment_id ? true : false;
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: isDowngrade ? messages.SUBSCRIPTION_CHANGE_IN_PROGRESS : messages.SUBSCRIPTION_PAYMENT_IN_PROGRESS } });

    let response = null;
    response = yield call(
      api_put,
      `/api/v1/subscriptions/plans/${subscription_id}`,
      { new_plan_id, customer_id, subscription_id, payment_id, user_id }
    );
    if (response.error) {
      yield put({ type: ActionTypes.PLAN_CHANGE_FAILED, error: response.error });
      yield put({ type: ActionTypes.SET_ERROR_MESSAGE, message: response.error && response.error.message ? response.error.message : response.error });

    }
    else {
      yield put({ type: ActionTypes.PLAN_CHANGE_SUCCEEDED, subscription: response.subscription });
      //yield put({ type: ActionTypes.SET_INFO_MESSAGE, message: isDowngrade ? messages.TOAST_SUBSCRIPTION_DOWNGRADE : messages.TOAST_SUBSCRIPTION_UPGRADE });

      if (response.subscription) {
        if (response.subscription.id) {
          localStorage.setItem(WISAR_SUBSCRIPTION_KEY, response.subscription.id)
        }
        yield put({ type: ActionTypes.SET_CURRENT_STATUS, current_status: response.subscription.status })
        localStorage.setItem(WISAR_SUBSCRIPTION_STATUS_KEY, response.subscription.status);
        if (response.token) {
          var decoded = jwt_decode(response.token);
          yield put({ type: ActionTypes.SET_JWT_OBJECT, decoded: decoded });
          localStorage.setItem(WISAR_TOKEN_KEY, response.token)
        }
        if (response.plan) {
          yield put({ type: ActionTypes.PLAN_SUCCEEDED, plan: response.plan });
        }
        if (isDowngrade) {
          yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: messages.RESULT_SUBSCRIPTION_DOWNGRADE } });
        }
        else {
          yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: true, text: messages.RESULT_SUBSCRIPTION_UPGRADE } });
        }

        yield delay(2000);
        yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
      }

    }
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
  } catch (error) {
    yield put({ type: ActionTypes.SET_LOADING, payload: { scope: 'subscription', loading: false, text: "" } });
    yield put({ type: ActionTypes.PLAN_CHANGE_FAILED, error });

  }
}
//export const changeSubscriptionPlan = (new_plan_id, customer_id, subscription_id, payment_id) => ({types:ActionTypes.PLAN_CHANGE_REQUESTED, new_plan_id, customer_id, subscription_id, payment_id});