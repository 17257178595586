import * as ActionTypes from "../constants/actionTypes";

const initialState = {
    display_message: '',
    error_message:''
}
/**
 * Displays flash messages
 * @param {*} state 
 * @param {*} action 
 */
const messagesReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {

        case ActionTypes.SET_INFO_MESSAGE: {
            return Object.assign({}, state, {
                display_message: action.message || ''
            });
        }
        case ActionTypes.RESET_TOAST_MESSAGE: {
            return Object.assign({}, state, {
                display_message: "",
                error_message:""
            });
        }
        case ActionTypes.SET_ERROR_MESSAGE: {
            return Object.assign({}, state, {
                error_message: action.message || ''
            });
        }
        default:
            return state;
    }

}
export default messagesReducer;