import * as ActionTypes from "../constants/actionTypes";

export function saveTalentProfile(payload) {
  return { type: ActionTypes.SAVE_PROFILE_REQUESTED, payload };
}
export const loadTalent = () => ({ type: ActionTypes.LOAD_PROFILE_REQUESTED });

export const savePersonalInfo = (payload) => ({
  type: ActionTypes.SAVE_PERSONAL_INFO_REQUESTED,
  payload,
});
export const saveProfessionalProfile = (payload) => ({
  type: ActionTypes.SAVE_PROFFESIONAL_PROFILE_REQUESTED,
  payload,
});
export const saveWorkPreference = (payload) => ({
  type: ActionTypes.SAVE_WORK_PREFERENCE_REQUESTED,
  payload,
});
export const saveCommunicationPreference = (payload) => ({
  type: ActionTypes.SAVE_COMMUNICATION_PREFERENCE_REQUESTED,
  payload,
});
export const loadCustomer = () => ({
  type: ActionTypes.LOAD_STRIPE_CUSTOMER_REQUESTED,
});
export const updateCustomer = (customer_id, customer) => ({
  type: ActionTypes.UPDATE_STRIPE_CUSTOMER_REQUESTED,
  customer_id,
  customer,
});
export const addCustomerTaxId = (customer_id, tax_id, tax_type) => ({
  type: ActionTypes.ADD_STRIPE_CUSTOMER_TAX_ID_REQUESTED,
  customer_id,
  tax_id,
  tax_type,
});
export const deleteCustomerTaxId = (customer_id, id) => ({
  type: ActionTypes.DELETE_STRIPE_CUSTOMER_TAX_ID_REQUESTED,
  customer_id,
  id,
});
export function cancelSubscription(subscription_id, customer_id) {
  return {
    type: ActionTypes.SUBSCRIPTION_CANCELATION_REQUESTED,
    subscription_id,
    customer_id,
  };
}
export const copyTalentAddresIntoBillingInfo = () => ({
  type: ActionTypes.COPY_TALENT_ADDRESS_INTO_CUSTOMER_ADDRESS,
});
export const openCustomerForm = () => ({
  type: ActionTypes.OPEN_CUSTOMER_FORM,
});
export const closeCustomerForm = () => ({
  type: ActionTypes.CLOSE_CUSTOMER_FORM,
});
export const getCustomerPaymentMethods = (customer_id) => ({
  type: ActionTypes.CUSTOMER_PAYMENT_METHODS_REQUESTED,
  customer_id,
});
export const deleteAccount = () => ({
  type: ActionTypes.DELETE_ACCOUNT_REQUESTED,
});

export function applyCouponToSubscription(subscription_id, coupon_id) {
  return {
    type: ActionTypes.SUBSCRIPTION_COUPON_REQUESTED,
    subscription_id,
    coupon_id,
  };
}
export const savePartialTalent = (payload, form_name, showDisplayMessage) => ({
  type: ActionTypes.SAVE_PARTIAL_TALENT_REQUESTED,
  payload,
  form_name,
  showDisplayMessage,
});

export const runMatchingForTalent = () => ({
  type: ActionTypes.MATCHING_TALENT_REQUESTED,
});

export const loadJobsPreviewForCategory = (categories) => ({
  type: ActionTypes.CATEGORY_JOBS_PREVIEW_REQUESTED,
  categories,
});
export const filterCategoriesInJobPreview = (categories) => ({
  type: ActionTypes.SUBCATEGORY_PREVIEW_CHANGED,
  categories,
});
export const createMatchesForCategories = (categories) => ({
  type: ActionTypes.PREVIEW_MATCHES_REQUESTED,
  categories,
});

export const getAllSkills = () => ({
  type: ActionTypes.ALL_SKILLS_REQUESTED,
});
