import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import get from "lodash/get";
import { isValidURL } from "../../utils/utils";
import { logout, getProfile } from "../../actions/access.actions";
import { setRedBarDisplayed } from "../../actions/ui.actions";
import { toggleLogin } from "../../actions/modals.actions";
import logo from "../../assets/img/nuevo-logo-wisar.png";
import user_image from "../../assets/img/user.png";
import hamburger_menu from "../../assets/img/menu-b.svg";
import "./header.css";
import moment from "moment";
import * as _ from "lodash";
import * as messages from "../../constants/messages";
import { ADMIN_ROLE } from "../../config";
function Header(props) {
  const onLogoutClick = () => {
    props.logoutUser();
    window.location.replace("https://wisar.pro/");
  };

  const onManageSubscriptionClick = () => {
    props.history.push("/account");
  };

  const onSettingsClick = () => {
    props.history.push("/settings");
  };

  const onLogoClick = () => {
    window.location.replace("https://wisar.pro/");
  };

  const onAdminClick = () => {
    props.history.push("/admin");
  };

  const getShortName = (name) => {
    return name && name.length > 14 ? name.substring(0, 13) : name;
  };
  const { userObj, percentage_completion, stripe_subscription, decoded_token } =
    props;
  let userInfoEle = null;
  let menuLinks = null;
  let profileImg = null;
  const isAuhenticated = get(userObj, "isAuthenticated") ? true : false;
  const isAdmin =
    decoded_token &&
    decoded_token.roles &&
    Array.isArray(decoded_token.roles) &&
    decoded_token.roles.indexOf(ADMIN_ROLE) > -1
      ? true
      : false;

  if (isAuhenticated) {
    const profile_picture = get(userObj, "loggedUserObj.picture_url");
    if (profile_picture && isValidURL(profile_picture)) {
      profileImg = userObj.loggedUserObj.picture_url;
    } else {
      profileImg = user_image;
    }
    let name = get(userObj, "loggedUserObj.email");
    if (get(userObj, "loggedUserObj.fullname")) {
      var nameLine = userObj.loggedUserObj.fullname
        ? getShortName(userObj.loggedUserObj.fullname.split(" ")[0])
        : null;
      name = nameLine;
    }
    menuLinks = (
      <ul className="navbar-nav ml-auto">
        {percentage_completion >= 80 && (
          <NavMenuLink
            to="/matches"
            label="My matches"
            activeOnlyWhenExact={true}
          ></NavMenuLink>
        )}
        {percentage_completion >= 80 && (
          <NavMenuLink
            to="/profile"
            label="My profile"
            activeOnlyWhenExact={true}
          ></NavMenuLink>
        )}
        {percentage_completion < 80 && (
          <NavMenuLink
            to="/onboard"
            label="My profile"
            activeOnlyWhenExact={true}
          ></NavMenuLink>
        )}
      </ul>
    );
    userInfoEle = (
      <table style={{ width: "100%", height: "100%" }}>
        <tbody>
          <tr>
            <td className="align-middle text-center">
              <ul className="list-unstyled userLinks">
                <li className="d-inline">
                  <a className="userImage">
                    <img
                      className="rounded-circle"
                      width="40"
                      height="40"
                      src={profileImg}
                      alt=""
                    />
                  </a>
                </li>
                <li className="d-inline">
                  <div className="btn-group">
                    <button
                      id="userDropDown"
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {name}
                    </button>
                    <div className="dropdown-menu dropdown-menu-main dropdown-menu-bottom">
                      <button
                        onClick={onManageSubscriptionClick}
                        className="dropdown-item"
                        type="button"
                      >
                        Manage subscription
                      </button>
                      <button
                        onClick={onSettingsClick}
                        className="dropdown-item"
                        type="button"
                      >
                        Settings
                      </button>
                      {isAdmin && (
                        <button
                          onClick={onAdminClick}
                          className="dropdown-item"
                          type="button"
                        >
                          Admin
                        </button>
                      )}
                      <button
                        onClick={onLogoutClick}
                        className="dropdown-item"
                        type="button"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </li>

                {/*         
                <li className="sessionOff d-inline ml-lg-4">
                  <a id="sessionOffLink" onClick={this.onLogoutClick}><img width="24" height="24" src="img/session-off.svg" alt="User Image" />
                  Logout
                  </a>
                </li> 
                */}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    menuLinks = (
      <ul className="navbar-nav ml-auto">
        <li
          className="nav-item"
          data-toggle="collapse"
          data-target="#mainNavBar"
        >
          <a className="nav-link" href="https://wisar.pro/">
            Home
          </a>
        </li>
        <li
          className="nav-item"
          data-toggle="collapse"
          data-target="#mainNavBar"
        >
          <a className="nav-link" href="https://wisar.pro/blog/">
            Blog
          </a>
        </li>
      </ul>
    );
    userInfoEle = (
      <ul className="navbar-nav ml-auto">
        <NavMenuLink
          to="/signup"
          label="Sign up"
          activeOnlyWhenExact={true}
        ></NavMenuLink>
        <NavMenuLink
          to="/login"
          label="Log in"
          activeOnlyWhenExact={true}
        ></NavMenuLink>
      </ul>
    );
  }
  let show = false;
  let notification_content = "";
  let trialStartedOn,
    trialEndOn = null;
  if (stripe_subscription) {
    if (
      stripe_subscription.status == "past_due" ||
      stripe_subscription.status == "incomplete"
    ) {
      let action_title = "";
      let action_message = "";
      if (
        props.stripe_subscription &&
        props.stripe_subscription.pending_setup_intent
      ) {
        const { status, last_setup_error } =
          props.stripe_subscription.pending_setup_intent;
        if (status === "requires_action") {
          action_title = messages.ACTION_ACTION_REQUIRED;
          action_message = messages.SUBSCRIPTION_AUTH_MESSAGE;
          notification_content = (
            <p>
              {action_message}{" "}
              <NavLink to="/account#manage_subscription">Update </NavLink>
            </p>
          );
          show = true;
        } else if (status === "requires_payment_method") {
          action_title = messages.ACTION_PAYMENT_METHOD_REQUIRED;
          action_message =
            last_setup_error && last_setup_error.message
              ? last_setup_error.message
              : messages.SUBSCRIPTION_UNPAID_MESSAGE;
          notification_content = (
            <p>
              {action_message}{" "}
              <NavLink to="/account#manage_subscription">Review</NavLink>
            </p>
          );
          show = true;
        } else {
          show = false;
        }
      } else if (
        props.stripe_subscription &&
        props.stripe_subscription.latest_invoice &&
        props.stripe_subscription.latest_invoice.payment_intent
      ) {
        const { status } =
          props.stripe_subscription.latest_invoice.payment_intent;
        if (status === "requires_action") {
          action_title = messages.ACTION_ACTION_REQUIRED;
          action_message = messages.SUBSCRIPTION_AUTH_MESSAGE;
          notification_content = (
            <p>
              {action_message}{" "}
              <NavLink to="/account#manage_subscription">
                Update payment
              </NavLink>
            </p>
          );
          show = true;
        } else if (status === "requires_payment_method") {
          action_title = messages.ACTION_PAYMENT_METHOD_REQUIRED;
          action_message = messages.SUBSCRIPTION_UNPAID_MESSAGE;
          notification_content = (
            <p>
              {action_message}{" "}
              <NavLink to="/account#manage_subscription">Add card</NavLink>
            </p>
          );
          show = true;
        } else {
          show = false;
        }
      }
    } else if (stripe_subscription.status === "trialing") {
      let days = 0;

      if (stripe_subscription.trial_start) {
        trialStartedOn = moment.unix(stripe_subscription.trial_start).format();
      }
      if (stripe_subscription.trial_end) {
        trialEndOn = moment.unix(stripe_subscription.trial_end).format();
      }

      if (trialStartedOn && trialEndOn) {
        days = moment().diff(trialEndOn, "days"); // 1
        if (days < 0) {
          days = days * -1;
        }
      }
      show = true;
      notification_content = <p>Your free trial ends in {days} days</p>;
    }
  }

  const logoNavClass = show
    ? "navbar navbar-expand-lg navbar-light repositioned-bar"
    : "navbar navbar-expand-lg fixed-top navbar-light";

  useEffect(() => {
    props.setRedBarDisplayed(show);
  });

  return (
    <div className="Header">
      {show && (
        <nav className="notification-bar fixed-top">
          <div className="container d-flex justify-content-center">
            {notification_content}
          </div>
        </nav>
      )}
      <header role="navigation">
        <nav className={logoNavClass}>
          <a id="wisarLogo" className="position-absolute text-left">
            <img src={logo} alt="Wisar" onClick={onLogoClick} />
          </a>
          <a
            id="togglerMainNavBar"
            className="navbar-toggler border-0 p-2"
            data-toggle="collapse"
            data-target="#mainNavBar"
            aria-controls="mainNavBar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img width="36" src={hamburger_menu} alt="Main menu" />
          </a>

          <div
            className="collapse navbar-collapse order-last order-lg-first"
            id="mainNavBar"
          >
            {menuLinks}
          </div>

          {
            <div className="navbar-nav navbar-right" id="userNavBar">
              {userInfoEle}
            </div>
          }
        </nav>
      </header>
    </div>
  );
}
Header.propTypes = {
  /* Router */
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  /* Redux */
  userObj: PropTypes.object,
  logoutUser: PropTypes.func,
  getProfile: PropTypes.func,
};

Header.defaultProps = {
  userObj: {},
  logoutUser: () => null,
  getProfile: () => null,
};

const mapStateToProps = (state) => ({
  userObj: state.access.user,
  current_status: state.talentProfileReducers.current_status,
  customer: state.talentProfileReducers.stripe_customer,
  percentage_completion: state.talentProfileReducers.percentage_completion,
  stripe_subscription: state.subscriptionReducers.stripe_subscription,
  decoded_token: state.access.decoded_token,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logout()),
  toggleLogin: (newState) => dispatch(toggleLogin(newState)),
  getProfile: () => dispatch(getProfile()),
  setRedBarDisplayed: (isShowing) => dispatch(setRedBarDisplayed(isShowing)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
function NavMenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <li className={match ? "nav-item active" : "nav-item"}>
      <NavLink to={to}>{label}</NavLink>
    </li>
  );
}
