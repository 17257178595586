
import * as ActionTypes from "../constants/actionTypes";
import * as _ from 'lodash';
const fields_map_section = {
    'job.dedication': 'dedication',
    'job.expertise_level': 'expertise level',
    "job.job_type": "contract type",
    "categories.name": "subcategories",
    "job.client_country": "client country",
    "job.origin": "plafform",
    "job_rate": "target budget"
}

const initialState = {
    matches: [],
    filtered_list: [],
    facets: [],
    initial_facets: null, //use for clearing filters
    applied_filters: [],//field_id, value, label
    totalDocs: 0,
    totalPages: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
    pagingCounter: 1,
    page: 1,
    n_per_page: 50,
    sort_field: 'posting_date',
    sort_label: 'Date desc',
    sort_order: 'desc',
    isLoading: false,
    x: '',
    loadFacets: true,
    fav_count: 0,
    total_unfiltered: 0,
    dateFrom: '',
    dateTo: '',
    onlyFavorites: false,
    matching_progress: undefined,
    filter_sections: null,
    initial_filter_sections: null,
    last_filtered_section: '',
    first_load: true
};

function matchesReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.CHECK_INITAL_FILTER_REQUESTED: {
            break;
        }
        case ActionTypes.MATCHING_PROGRESS_SUCCEEDED:
            return Object.assign({}, state, {
                matching_progress: action.progress
            });
        case ActionTypes.MATCHING_PROGRESS_FAILED:
            return Object.assign({}, state, {
                matching_progress: 100
            });
        case ActionTypes.DATA_REQUESTED: {
            return Object.assign({}, state, {
                isLoading: true,
                searchText: '',
            });
        }
        case ActionTypes.SET_INITIAL_FACETS: {
            if (!state.initial_facets) {
                delete state.initial_facets;
                return Object.assign({}, state, {
                    initial_facets: action.initial_facets
                });
            }
            break;
        }
        case ActionTypes.DATA_LOADED: {
            const reaload_facets = state.first_load;
            const loaded_facets = action.payload.facets && action.payload.facets[0] ? action.payload.facets[0] : null;
            let my_filter_sections = reaload_facets ? formatFilterOptions(loaded_facets) : mergeFacetCount(state.filter_sections, loaded_facets);//formatFilterOptions(action.payload.facets[0]),
            
            if (state.loadFacets && action.payload && action.payload.availability_filters) {
                const indexSection = _.findIndex(my_filter_sections, { section_name: 'dedication' })

                let section = indexSection > -1 ? my_filter_sections[indexSection] : null;
                if (section && section.option_list) {
                    action.payload.availability_filters.forEach(element => {
                        if (element && _.findIndex(section.option_list, { _id: element.value }) > -1) {
                            action.asyncDispatch({ type: ActionTypes.TOGGLE_FILTER, field_id: element.field_id, value: element.value, subtitle: element.subtitle });
                        }
              
                    });
                }
            }
      
               return Object.assign({}, state, {
                filtered_list: action.payload ? action.payload.matches : [],
                totalPages: action.payload ? action.payload.totalPages : 0,
                hasPrevPage: action.payload ? action.payload.hasPrevPage : 0,
                hasNextPage: action.payload ? action.payload.hasNextPage : 0,
                prevPage: action.payload ? action.payload.prevPage : 0,
                nextPage: action.payload ? action.payload.nextPage : 0,
                pagingCounter: action.payload ? action.payload.pagingCounter : 0,
                page: action.payload ? action.payload.page : 0,
                totalDocs: action.payload ? action.payload.totalDocs : 0,
                // load not first time
                facets: state.loadFacets && action.payload && action.payload.facets.length > 0 ? loaded_facets : null,
                total_unfiltered: state.loadFacets && reaload_facets ? action.payload.totalDocs : 0,
                matches: state.loadFacets && reaload_facets && action.payload ? action.payload.matches : [],
                filter_sections: my_filter_sections,
                isLoading: false,
                loadFacets: false,
                first_load: false
            });
        }
        case ActionTypes.SET_LOAD_INITIAL_FACETS: {
            return Object.assign({}, state, {
                loadFacets: action.bool
            });
        }
        case ActionTypes.FACETS_RELOADED: {
            return Object.assign({}, state, {
                facets: action.facets && action.facets.length > 0 ? action.facets[0] : [],
                filter_sections: formatFilterOptions(action.facets[0])
            });
        }
        case ActionTypes.CHANGE_PAGE:
            return Object.assign({}, state, {
                page: action.payload
            });
        case ActionTypes.ADD_FILTER: {
            const new_filter = { field_id: action.payload.field_id, value: action.payload.value, values: action.payload.values, label: action.payload.label ? action.payload.label : action.payload.value };
            return Object.assign({}, state, {
                applied_filters: [...state.applied_filters || [], new_filter],
                dateFrom: action.payload.field_id === 'MatchDates' ? action.payload.values[0] : state.dateFrom,
                dateTo: action.payload.field_id === 'MatchDates' ? action.payload.values[1] : state.dateTo,
                onlyFavorites: action.payload.field_id === 'rating' ? true : state.onlyFavorites
            });
        }
        case ActionTypes.REMOVE_FILTER: {
            var condition = { field_id: action.payload.field_id }
            if (action.payload.value) { // if no value passed, then removed the id
                condition['value'] = action.payload.value;
            }
            let filters = state.applied_filters ? [...state.applied_filters] : [];
            const index = _.findIndex(filters, condition)
            if (index > -1) {
                return Object.assign({}, state, {
                    applied_filters: [
                        ...filters.slice(0, index),
                        ...filters.slice(index + 1)
                    ],
                    dateFrom: action.payload.field_id === 'MatchDates' ? '' : state.dateFrom,
                    dateTo: action.payload.field_id === 'MatchDates' ? '' : state.dateTo,
                    onlyFavorites: action.payload.field_id === 'rating' ? false : state.onlyFavorites
                });
            }
            else return state;
        }
        /**
         * Iterates over the original facets and toggles 'isChecked' field.
         * Adds or remove item into/from filter list according to checked option.
         * 
         */
        case ActionTypes.TOGGLE_FILTER: {
            let condition = { field_id: action.field_id, value: action.value }
            let filters = state.applied_filters ? [...state.applied_filters] : [];
            const indexSection = _.findIndex(state.filter_sections, { section_name: action.subtitle })
            let section = state.filter_sections[indexSection];

            let section_array = section.option_list.map((todo, index) => {
                var item_value = Array.isArray(todo['_id']) ? todo['_id'][0] : getMinMax(todo['_id']);

                if (item_value === action.value) {
                    todo.isChecked = !todo.isChecked;
                    const index = _.findIndex(filters, condition)

                    if (todo.isChecked && index === -1) { //adds it
                        const new_filter = { field_id: action.field_id, value: action.value, values: action.values, label: action.label ? action.label : action.value };
                        filters.push(new_filter);
                    }
                    else {// removes it
                        _.remove(filters, condition);
                    }
                }

                return todo;
            }
            )
            let check = _.filter(section_array, { 'isChecked': true }).length
            action.asyncDispatch({ type: ActionTypes.DATA_FILTER_REQUESTED, value: filters });
            return Object.assign({}, state, {
                filter_sections: state.filter_sections.map((section, i) =>
                    i === indexSection ? { ...section, option_list: section_array, check_count: check } : section
                ),
                applied_filters: filters,
                last_filtered_section: filters && filters.length > 0 ? action.subtitle : '',
            });
        }
        case ActionTypes.TOGGLE_SECTION: {
            let sections = state.filter_sections.map(section =>
                section.section_name === action.section_name ? { ...section, expanded: !section.expanded } : section
            )
            return Object.assign({}, state, {
                filter_sections: sections
            });
        }
        case ActionTypes.SEARCH_TEXT_REQUESTED: {
            return Object.assign({}, state, {
                filter_sections: [],
                applied_filters: [],
                loadFacets: true,
                isLoading: true,
                dateFrom: initialState.dateFrom,
                dateTo: initialState.dateTo,
                onlyFavorites: initialState.onlyFavorites,
                searchText: action.includeText
            });
        }
        case ActionTypes.SET_ORDER:
            var filtered = null;
            if (action.payload.sort_field === 'job_rate') {
                filtered = _.orderBy(state.filtered_list, function (obj) {
                    var price = obj.job && obj.job.usd_budget ? parseInt(obj.job.usd_budget, 10) : 0;
                    return price;
                }, [action.payload.sort_order]);
            }
            else {
                filtered = _.orderBy(state.filtered_list, [action.payload.sort_field], [action.payload.sort_order]);
            }

            return Object.assign({}, state, {
                sort_field: action.payload.sort_field,
                sort_label: action.payload.sort_label,
                sort_order: action.payload.sort_order,
                filtered_list: filtered
            });
        case ActionTypes.LOGOUT_SUCCEEDED: {
            return Object.assign({}, state, initialState);
        }
        case ActionTypes.SET_RATING: {
            var count = state.fav_count;
            const match_id = action.payload.match_id;
            const indexmatch = _.findIndex(state.filtered_list, { _id: match_id })
            const original = state.filtered_list[indexmatch];
            const previous_rating = original.rating || 0;
            if (action.payload.rating === 5) {
                count += 1;
            }
            else if (previous_rating === 5 && (action.payload.rating === -1 || action.payload.rating === 0)) {
                count -= 1;
            }

            return Object.assign({}, state, {
                fav_count: count,
                filtered_list: state.filtered_list.map((match, i) =>
                    i === indexmatch ? { ...match, rating: action.payload.rating } : match
                ),
            });
        }
        case ActionTypes.FAVORITES_COUNT_SUCCEEDED: {
            return Object.assign({}, state, { fav_count: action.count });
        }

        //clears Array of objects. Example array object:{section_name: "dedication", option_list: Array(4), items_to_show: 3, expanded: false, check_count: 1}
        case ActionTypes.CLEAR_FILTERS: {
            action.asyncDispatch({ type: ActionTypes.DATA_FILTER_REQUESTED }); 
            return Object.assign({}, state, {
                applied_filters: [],
                facets: initialState.facets,
                dateFrom: initialState.dateFrom,
                dateTo: initialState.dateTo,
                onlyFavorites: initialState.onlyFavorites,
                filter_sections: resetFacetCount(state.filter_sections),//formatFilterOptions(state.initial_facets),
                last_filtered_section: ''
            });
        }
        default:
            return state;
    };

    function getMinMax(label) {
        if (label && label.hasOwnProperty("min") && label.hasOwnProperty("max")) {
            return label.min + " - " + label.max;
        }
        return label;
    }
    function formatFilterOptions(facets) {
        if (facets) {
            let sections = [];
            for (const key in facets) {
                sections.push(
                    {
                        section_name: key,
                        option_list: facets[key],
                        items_to_show: 3,
                        expanded: false
                    }
                )

            }
            //console.log("sections ::::", sections);
            return sections;
        }
        else {
            return null;
        }
    }
    //UNused added 7h January
    function resetFacetCount(old_facets_array) {
        let updatedArr = [];
        updatedArr = old_facets_array.map(section => {
            let oldsection = state.initial_facets[section.section_name];
            if (oldsection) {
                section.option_list = section.option_list.map((option, index) => {
                    const newCount = _.find(oldsection, { _id: option._id });
                    if (newCount) {
                        option.count = newCount.count;
                        option.isChecked = false;
                    }
                    return option;
                })
                section.check_count=0;
            }


            return section;
        });
        return updatedArr;
    }
    /**
     * Updates facets with refreshed count of filtered documents
     * @param {*} old_facets_array 
     * @param {*} facets_dict 
     */
    function mergeFacetCount(old_facets_array, facets_dict) {
        if (old_facets_array && facets_dict) {
            let updatedArr = [];


            updatedArr = old_facets_array.map(section => {
                let newsection = facets_dict[section.section_name];

                if (newsection && (section.section_name !== state.last_filtered_section) || (section.section_name === state.last_filtered_section && section.check_count === 0)) { //loop option list to update count
                    section.option_list = section.option_list.map((option, index) => {
                        const newCount = _.find(newsection, { _id: option._id });
                        if (newCount) {
                            option.count = newCount.count;
                        }
                        else {
                            if (section.check_count === 0){ // section has other filters
                                option.count = 0;
                            }
                        }
                        return option;
                    })

                }
                return section;
            });
            //No need to reset if searching by text 
            if (!state.searchText && state.initial_facets && state.applied_filters && state.applied_filters.length === 1) {
                // set initial value
                const section_to_reset = fields_map_section[state.applied_filters[0].field_id];

                updatedArr = old_facets_array.map(section => {
                    let oldsection = state.initial_facets[section.section_name];
                    if (section.section_name === section_to_reset && oldsection) {
                        section.option_list = section.option_list.map((option, index) => {
                            const newCount = _.find(oldsection, { _id: option._id });
                            if (newCount) {
                                option.count = newCount.count;
                            }
                            return option;
                        })
                    }

                    return section;
                });

            }


            return updatedArr;

        }
        else {
            return old_facets_array;
        }
    }
}
export default matchesReducer;