import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import { api_get, api_post, api_put } from "../utils/api";
import { push } from "connected-react-router";
import Cookies from "js-cookie";
import {
  COOKIE_USER,
  WISAR_TOKEN_KEY,
  WISAR_SUBSCRIPTION_STATUS_KEY,
  WISAR_SUBSCRIPTION_KEY,
  LAST_LOCATION_KEY,
  ELIGIBLE_SUBSCRIPTION_URL_KEY,
} from "../config";
import jwt_decode from "jwt-decode";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* signup(action) {
  const { email, password, fullname } = action.payload;
  const utm = action.utm;
  yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  try {
    Cookies.remove(COOKIE_USER);
    localStorage.removeItem(WISAR_TOKEN_KEY);
    localStorage.removeItem(WISAR_SUBSCRIPTION_KEY);
    const response = yield call(api_post, "/signup", {
      email,
      password,
      fullname,
      utm,
    });
    if (response.error || !response.user) throw response;
    else {
      yield put({
        type: ActionTypes.SIGNUP_SUCCEEDED,
        message: response.message,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.SIGNUP_FAILED, error });
  }
}

export function* watchSignupSaga() {
  yield takeLatest(ActionTypes.SIGNUP_REQUESTED, signup);
}

function* login(action) {
  yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });

  const { email, password } = action;
  Cookies.remove(COOKIE_USER);
  // localStorage.removeItem(WISAR_TOKEN_KEY);
  //localStorage.removeItem(WISAR_SUBSCRIPTION_KEY);
  localStorage.clear();

  try {
    const response = yield call(api_post, "/login", { email, password });
    if (response.error) throw response;
    else if (!response.user) {
      yield put({
        type: ActionTypes.LOGIN_FAILED,
        error:
          "Error establishing connection to server. Please try again later.",
      });
    } else {
      if (response.token) {
        var decoded = jwt_decode(response.token);
        yield put({ type: ActionTypes.SET_JWT_OBJECT, decoded: decoded });
        localStorage.setItem(WISAR_TOKEN_KEY, response.token);
      }
      const inOneWeek = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 7
      );
      Cookies.set(COOKIE_USER, response.user, {
        expires: inOneWeek,
        samesite: "strict",
      });
      let subscription_id = "";
      yield put({
        type: ActionTypes.SET_CURRENT_STATUS,
        current_status: response.subscription_status,
      });
      if (
        response.subscription_id &&
        response.subscription_id !== null &&
        response.subscription_id !== "null" &&
        response.subscription_id !== undefined &&
        response.subscription_id !== "undefined"
      ) {
        subscription_id = response.subscription_id;
        localStorage.setItem(WISAR_SUBSCRIPTION_KEY, subscription_id);
        yield put({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_REQUESTED });
      }
      yield put({
        type: ActionTypes.LOGIN_SUCCEEDED,
        user: response.user,
        token: response.token,
        access_provider: "local",
      });
      const status = response.subscription_status;
      localStorage.setItem(WISAR_SUBSCRIPTION_STATUS_KEY, status);

      if (status && (status === "incomplete" || status === "past_due")) {
        yield put(push("/account"));
      } else if (!subscription_id) {
        /**
         * If user does not have an active subscription, then redirect to subs page.
         */
        localStorage.setItem(
          ELIGIBLE_SUBSCRIPTION_URL_KEY,
          response.subscription_url
        );
        yield put(push("/subscription"));
      } else {
        let lastVisited = localStorage.getItem(LAST_LOCATION_KEY);
        if (
          lastVisited &&
          lastVisited !== null &&
          lastVisited !== "null" &&
          lastVisited !== undefined &&
          lastVisited !== "undefined"
        ) {
          yield put(push(lastVisited));
          localStorage.removeItem(LAST_LOCATION_KEY);
        } else {
          if (
            !response.percentage_completion ||
            response.percentage_completion < 80
          ) {
            yield put(push("/onboard"));
          } else {
            yield put(push("/matches"));
          }
          yield put({
            type: ActionTypes.SET_PERCENTAGE_COMPLETION,
            percentage_completion: response.percentage_completion || 0,
          });
        }
      }
      yield put({ type: ActionTypes.LOAD_PROFILE_REQUESTED });
      yield delay(5000);
      yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
    }
  } catch (error) {
    yield put({ type: ActionTypes.LOGIN_FAILED, error });
  }
}

export function* watchLoginSaga() {
  yield takeLatest(ActionTypes.LOGIN_REQUESTED, login);
}

function* validateAccessToken(action) {
  yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });

  const { token } = action;
  try {
    const response = yield call(api_get, `/me/from/token?token=${token}`);
    if (response.error) throw response;
    else if (!response.user) {
      yield put({
        type: ActionTypes.API_ERRORED,
        error:
          "Error establishing connection to server. Please try again later.",
      });
    } else {
      const inOneWeek = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 7
      );
      Cookies.set(COOKIE_USER, response.user, {
        expires: inOneWeek,
        samesite: "strict",
      });
      //localStorage.setItem(WISAR_TOKEN_KEY, response.token)
      if (response.token) {
        var decoded = jwt_decode(response.token);
        yield put({ type: ActionTypes.SET_JWT_OBJECT, decoded: decoded });
        localStorage.setItem(WISAR_TOKEN_KEY, response.token);
      }
      let subscription_id = "";
      yield put({
        type: ActionTypes.SET_CURRENT_STATUS,
        current_status: response.subscription_status,
      });
      if (
        response.subscription_id &&
        response.subscription_id !== null &&
        response.subscription_id !== "null" &&
        response.subscription_id !== undefined &&
        response.subscription_id !== "undefined"
      ) {
        subscription_id = response.subscription_id;
        localStorage.setItem(WISAR_SUBSCRIPTION_KEY, subscription_id);
        localStorage.setItem(
          WISAR_SUBSCRIPTION_STATUS_KEY,
          response.subscription_status
        );
      } else {
        localStorage.removeItem(WISAR_SUBSCRIPTION_KEY);
        localStorage.removeItem(WISAR_SUBSCRIPTION_STATUS_KEY);
        if (window.location.pathname === "/matches") {
          yield put({
            type: ActionTypes.FORCE_LOGIN,
            error: "You dont have a subscription",
          });
          return;
        }
      }

      // TODO Access provider local
      yield put({
        type: ActionTypes.VALIDATE_ACCESS_TOKEN_SUCCEEDED,
        user: response.user,
        token: response.token,
        access_provider: "local",
      });
    }
  } catch (error) {
    console.log("validate", error);
    yield put({ type: ActionTypes.FORCE_LOGIN, error });
  }
}
export function* watchValidateAccessTokenSaga() {
  yield takeLatest(
    ActionTypes.VALIDATE_ACCESS_TOKEN_REQUESTED,
    validateAccessToken
  );
}
export function* watchForceLoginTokenSaga() {
  yield takeLatest(ActionTypes.FORCE_LOGIN, forceLogin);
}
/**
 * Clears storage and Redirects to Login
 */
function* forceLogin() {
  try {
    yield put({ type: ActionTypes.LOGOUT_REQUESTED });
    yield put(push("/login"));
  } catch (error) {
    yield put({ type: ActionTypes.API_ERRORED, error });
  }
}
/**
 * Clears the storage
 */
function* logout() {
  try {
    Cookies.remove(COOKIE_USER);
    localStorage.clear();
    yield put({ type: ActionTypes.LOGOUT_SUCCEEDED });
  } catch (error) {
    yield put({ type: ActionTypes.LOGOUT_FAILED, error });
  }
}

export function* watchLogout() {
  yield takeLatest(ActionTypes.LOGOUT_REQUESTED, logout);
}

function* getProfile() {
  try {
    const response = yield call(api_get, "/api/v1/users/profile");
    if (response.error) throw response;
    const inOneWeek = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
    Cookies.set(COOKIE_USER, response, {
      expires: inOneWeek,
      samesite: "strict",
    });
    yield put({ type: ActionTypes.PROFILE_SUCCEEDED, user: response });
  } catch (error) {
    yield put({ type: ActionTypes.PROFILE_FAILED, error });
  }
}

export function* watchGetProfile() {
  yield takeLatest(ActionTypes.PROFILE_REQUESTED, getProfile);
}
/**
 * Sends password reset link
 */

function* passwordForgot(action) {
  const { email } = action;

  try {
    const response = yield call(api_post, `/api/v1/users/forgot/${email}`, {
      email,
    });
    if (response.error) throw response;
    yield put({
      type: ActionTypes.FORGOT_PASSWORD_SUCCEEDED,
      message: response.message,
    });
    yield delay(5000);
    yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  } catch (error) {
    yield put({ type: ActionTypes.SIGNUP_FAILED, error });
  }
}

export function* watchPasswordForgotSaga() {
  yield takeLatest(ActionTypes.FORGOT_PASSWORD_REQUESTED, passwordForgot);
}
/**
 * Pasword change
 */

function* passwordChange(action) {
  const { old_password, password } = action;
  //take email
  let u = Cookies.getJSON(COOKIE_USER);
  let email = u ? u.email : null;

  if (!email) {
    // No user logged in or subscription not completed
    yield put(push("/"));
  }
  try {
    const response = yield call(
      api_put,
      `/api/v1/users/password-change/${email}`,
      { old_password, password }
    );
    if (response.error) throw response;
    yield put({
      type: ActionTypes.PASSWORD_CHANGE_SUCCEEDED,
      message: response.message,
    });
    yield delay(5000);
    yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  } catch (error) {
    yield put({ type: ActionTypes.SIGNUP_FAILED, error });
  }
}
export function* watchPasswordChangeSaga() {
  yield takeLatest(ActionTypes.PASSWORD_CHANGE_REQUESTED, passwordChange);
}

function* passwordReset(action) {
  const { token, password } = action;
  try {
    const response = yield call(
      api_put,
      `/api/v1/users/reset-password/token/`,
      { token, password }
    );
    if (response.error) throw response;

    yield put({
      type: ActionTypes.PASSWORD_RESET_SUCCEEDED,
      message: response.message,
    });
    yield put(push("/matches"));
    yield delay(5000);
    yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  } catch (error) {
    yield put({ type: ActionTypes.SIGNUP_FAILED, error });
  }
}
export function* watchPasswordResetSaga() {
  yield takeLatest(ActionTypes.PASSWORD_RESET_REQUESTED, passwordReset);
}
/**
 * Social login
 * @param {} action
 */
function* oauth(action) {
  const { provider, token } = action;
  const utm = action.utm;

  yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
  Cookies.remove(COOKIE_USER);
  localStorage.removeItem(WISAR_TOKEN_KEY);
  localStorage.removeItem(WISAR_SUBSCRIPTION_KEY);

  try {
    const response = yield call(api_post, `/oauth/${provider}`, { token, utm });

    if (response.error) throw response;
    else if (!response.user) {
      yield put({
        type: ActionTypes.LOGIN_FAILED,
        error:
          "Error establishing connection to server. Please try again later.",
      });
    } else {
      if (response.token) {
        var decoded = jwt_decode(response.token);
        yield put({ type: ActionTypes.SET_JWT_OBJECT, decoded: decoded });
        localStorage.setItem(WISAR_TOKEN_KEY, response.token);
      }
      const inOneWeek = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24 * 7
      );
      Cookies.set(COOKIE_USER, response.user, {
        expires: inOneWeek,
        samesite: "strict",
      });
      let subscription_id = "";
      if (response.subscription_status) {
        yield put({
          type: ActionTypes.SET_CURRENT_STATUS,
          current_status: response.subscription_status,
        });
      }
      if (
        response.subscription_id &&
        response.subscription_id !== null &&
        response.subscription_id !== "null" &&
        response.subscription_id !== undefined &&
        response.subscription_id !== "undefined"
      ) {
        subscription_id = response.subscription_id;
        localStorage.setItem(WISAR_SUBSCRIPTION_KEY, subscription_id);
        yield put({ type: ActionTypes.LOAD_SUBSCRIPTION_ID_REQUESTED });
      }
      // yield localStorage.setItem("token", response.data.data.access_token);
      yield put({
        type: ActionTypes.LOGIN_SUCCEEDED,
        user: response.user,
        token: response.token,
        access_provider: provider,
      });
      const status = response.subscription_status;
      localStorage.setItem(
        WISAR_SUBSCRIPTION_STATUS_KEY,
        response.subscription_status
      );
      if (status && (status === "incomplete" || status === "past_due")) {
        yield put(push("/account"));
      } else if (!subscription_id) {
        /**
         * If user does not have an active subscription, then redirect to subs page.
         */
        localStorage.setItem(
          ELIGIBLE_SUBSCRIPTION_URL_KEY,
          response.subscription_url
        );
        yield put(push("/subscription"));
      } else {
        let lastVisited = localStorage.getItem(LAST_LOCATION_KEY);
        if (
          lastVisited &&
          lastVisited !== null &&
          lastVisited !== "null" &&
          lastVisited !== undefined &&
          lastVisited !== "undefined"
        ) {
          yield put(push(lastVisited));
          localStorage.removeItem(LAST_LOCATION_KEY);
        } else {
          if (
            !response.percentage_completion ||
            response.percentage_completion < 80
          ) {
            yield put(push("/onboard"));
          } else {
            yield put(push("/matches"));
          }
        }
        yield put({
          type: ActionTypes.SET_PERCENTAGE_COMPLETION,
          percentage_completion: response.percentage_completion || 0,
        });
      }
      yield put({ type: ActionTypes.LOAD_PROFILE_REQUESTED });
      yield delay(5000);
      yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES });
    }
  } catch (error) {
    yield put({ type: ActionTypes.OAUTH_FAILED, error });
  }
}

export function* watchOauthSaga() {
  yield takeLatest(ActionTypes.OAUTH_REQUESTED, oauth);
}

/**
 *
 * @param {*} action
 */
function* accountActivation(action) {
  const { token } = action;
  try {
    Cookies.remove(COOKIE_USER);
    localStorage.clear();

    const response = yield call(
      api_put,
      `/api/v1/users/activate-token/${token}`,
      { token }
    );
    if (response.error) throw response;
    else {
      yield put({
        type: ActionTypes.ACCOUNT_ACTIVATION_SUCCEEDED,
        message: response.message,
        email: response.email,
      });
      var url = "/login";
      if (response.email) {
        url += `?email=${response.email}`;
      }
      yield put(push(url));

      //   yield delay(15000);
      // yield put({ type: ActionTypes.CLEAR_INFO_MESSAGES }); rm april 30th
    }
  } catch (error) {
    yield put({ type: ActionTypes.LOGIN_FAILED, error });
  }
}
export function* watchAccountActivationSaga() {
  yield takeEvery(ActionTypes.ACCOUNT_ACTIVATION_REQUESTED, accountActivation);
}
/**
 *
 * @param {*} action
 */
function* resendActivation(action) {
  const { email } = action;
  try {
    const response = yield call(
      api_put,
      `/api/v1/users/resend-activation-email`,
      { email }
    );
    if (response.error) throw response;

    yield put({
      type: ActionTypes.RESEND_ACTIVATION_TOKEN_SUCCEEDED,
      message: response.message,
    });
    //  yield put(push('/login'))
    // yield delay(10000)
  } catch (error) {
    yield put({ type: ActionTypes.SIGNUP_FAILED, error });
  }
}
export function* watchResendActivationnSaga() {
  yield takeLatest(
    ActionTypes.RESEND_ACTIVATION_TOKEN_REQUESTED,
    resendActivation
  );
}
