import * as ActionTypes from '../constants/actionTypes';

/**
 * Authenticates an existing user
 * @param {*} email 
 * @param {*} password 
 */
export const login = (email, password) => ({
  type: ActionTypes.LOGIN_REQUESTED,
  email,
  password
});
/**
 * Creates a new user
 * @param {} payload 
 */
export function signup(payload, utm) {
  return { type: ActionTypes.SIGNUP_REQUESTED, payload, utm };
}
/**
 * Clears user data in store.
 */
export const logout = () => ({
  type: ActionTypes.LOGOUT_REQUESTED,
});
/**
 * Get user information
 */
export const getProfile = () => ({
  type: ActionTypes.PROFILE_REQUESTED,
});
/**
 * Generates a token a send an email for password reset
 * @param {*} email 
 */
export function forgotPassword(email) {
  return { type: ActionTypes.FORGOT_PASSWORD_REQUESTED, email };
}
/**
 * Validates old password and set a new password
 * @param {*} email 
 * @param {*} old_password 
 * @param {*} new_password 
 */
export function changePassword(old_password, password) {
  return { type: ActionTypes.PASSWORD_CHANGE_REQUESTED, old_password, password };
}
/**
 * Sets new password after token verification
 * @param {*} email 
 * @param {*} new_password 
 */
export function resetPassword(token, password) {
  return { type: ActionTypes.PASSWORD_RESET_REQUESTED, token, password };
}
/**
 * Validates token of oath provider in our Server
 * @param {*} provider 
 * @param {*} token 
 */
export function oauth(provider, token, utm) {
  return { type: ActionTypes.OAUTH_REQUESTED, provider, token, utm};
} 
/**
 * Sends to the server the activation token received by email to activate user account.
 * @param {*} token 
 */
export function activateAccount(token) {
  return { type: ActionTypes.ACCOUNT_ACTIVATION_REQUESTED, token };
}
/**
 * Resends activation token by email
 * @param {*} email 
 */
export function resendActivationEmail(email) {
  return { type: ActionTypes.RESEND_ACTIVATION_TOKEN_REQUESTED, email };
}
export function validateAccessToken(token){
  return { type: ActionTypes.VALIDATE_ACCESS_TOKEN_REQUESTED, token };

}
export function validateAccessTokenSucceeded(payload){
  return { type: ActionTypes.VALIDATE_ACCESS_TOKEN_SUCCEEDED, payload };
}
export function clearMessages(){
  return { type: ActionTypes.CLEAR_INFO_MESSAGES };
}