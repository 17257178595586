import { SHOW_MODAL, HIDE_MODAL, TOGGLE_MODAL_REQUESTED} from '../constants/actionTypes';

export const toggleLogin = newState => ({
  type: TOGGLE_MODAL_REQUESTED,
  newState,
});
/**
 * Displays a modal
 * @param {*} payload: modalProps, modalType 
 */
export const showModal = (payload) => ({
  type: SHOW_MODAL,
  payload
});

export const hideModal = () => ({
  type: HIDE_MODAL
});