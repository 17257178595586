import React from 'react';
import loading_icon from "../../assets/img/loading.gif";

const MiniNoSubmitButton = ({ processing, error, children, disabled, onClick, p_class }) =>{
  const className = p_class ? p_class +  ' text-uppercase': 'backButton text-uppercase';

  return (
    <button
      className={className}
      type="button"
      disabled={processing || disabled}
      onClick={onClick}
    >
      {processing ? <img className='loadingIcon' src={loading_icon} alt='...' width="30px" /> : children}
    </button>
  );}
export default MiniNoSubmitButton;