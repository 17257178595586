import React from 'react'
import './modal.css';
import { DONOTSHOW_PLATFORM_KEY } from '../../config';
import MiniNoSubmitButton from "../renders/mininosubmit_button";
import * as messages from '../../constants/messages';

const handleCheck = (e) => {
  //e.preventDefault();
  if (e.target.checked) {
    localStorage.setItem(DONOTSHOW_PLATFORM_KEY, true);
  }
  else {
    localStorage.removeItem(DONOTSHOW_PLATFORM_KEY);
  }
}

const AlertModal =(props)  => {
const handleJump =(e) => {
  props.increaseJumpCount();
}

  return (

    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p dangerouslySetInnerHTML={{ __html: props.message }} />
      </div>
      <div className="d-flex justify-content-between">
        <div className="custom-control custom-checkbox mt-3 signUpCheckbox">

          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="donotshow" name="donotshow" onChange={handleCheck} />
            <label className="custom-control-label" htmlFor="donotshow" >Do not show me this again </label>
          </div>
        </div>
  {props.link && <MiniNoSubmitButton p_class="my-button" onClick={handleJump}><a href={props.link} target="_blank" rel="noopener noreferrer" >{messages.JUM_TO_PLATFORM}</a></MiniNoSubmitButton>}
        {/* {props.link && <button type="button" className="btn btn-primary signUpButton mt-4 text-uppercase" onClick={handleJump}><a href={props.link} target="_blank">Jump to original platform</a></button>} */}
        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={closeModal}>close</button> */}
      </div>
      <br/>
    </div>

  )
}

export default AlertModal
